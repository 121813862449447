import React from 'react'
import PropTypes from 'prop-types'

import { DataGrid } from '@mui/x-data-grid'

const CustomPaginationDataGrid = ({
  columns,
  items,
  limit,
  loading,
  page,
  totalItems,
  setPage,
  setLimit,
}) => (
  <DataGrid
    sx={{ width: '100%', height: 'calc(100vh - 250px)', marginBottom: '20px' }}
    rows={items}
    columns={columns}
    loading={loading}
    hideFooterSelectedRowCount
    rowCount={totalItems}
    pageSize={limit}
    page={page}
    rowsPerPageOptions={[10, 30, 50, 80, 100]}
    disableColumnReorder
    pagination
    paginationMode="server"
    onPageChange={newPage => setPage(newPage)}
    onPageSizeChange={newPageSize => setLimit(newPageSize)}
  />
)

CustomPaginationDataGrid.propTypes = {
  columns: PropTypes.instanceOf(Array).isRequired,
  items: PropTypes.instanceOf(Array).isRequired,
  limit: PropTypes.number,
  loading: PropTypes.bool,
  page: PropTypes.number,
  totalItems: PropTypes.number.isRequired,
  setPage: PropTypes.func,
  setLimit: PropTypes.func,
}

CustomPaginationDataGrid.defaultProps = {
  limit: 10,
  loading: false,
  page: 0,
  setPage: () => {},
  setLimit: () => {},
}
export default CustomPaginationDataGrid
