import { CircularProgress, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { showSnackbar } from '../../actions/pageActions'
import { DOCTORS_PATH } from '../../constants/endpointsConstants'
import { client } from '../../utils/client'
import { getDoctorFulName } from '../../utils/doctor'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'

const SelectDoctor = ({ onDoctorSelected, defaultDoctor, requester }) => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [doctors, setDoctors] = useState(
    defaultDoctor?.id ? [defaultDoctor] : []
  )
  const [doctorSelected, setDoctorSelected] = useState(
    defaultDoctor?.id
      ? defaultDoctor
      : requester
      ? { name: requester || '', lastNameFather: '', lastNameMother: '' }
      : null
  )
  const filter = createFilterOptions()

  const handleOpenSelect = async () => {
    if (doctors.length > 1) return
    setLoading(true)
    try {
      const {
        data: { data: doctorsResult },
      } = await client.get(DOCTORS_PATH)

      setDoctors(doctorsResult)
    } catch (error) {
      dispatch(showSnackbar(error?.response?.data?.message))
    }
    setLoading(false)
  }
  const handleFieldChange = (event, newValue) => {
    let doctor = null
    if (typeof newValue === 'string') {
      doctor = {
        name: newValue,
        lastNameFather: '',
        lastNameMother: '',
      }
    } else if (newValue?.inputValue) {
      doctor = {
        name: newValue.inputValue,
        lastNameFather: '',
        lastNameMother: '',
      }
    } else {
      doctor = newValue
    }
    setDoctorSelected(doctor)
    onDoctorSelected(doctor)
  }

  return (
    <Autocomplete
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      freeSolo
      onOpen={handleOpenSelect}
      loading={loading}
      value={doctorSelected}
      options={doctors}
      onChange={handleFieldChange}
      filterOptions={(options, params) => {
        const filtered = filter(options, params)
        const { inputValue } = params
        // Suggest the creation of a new value
        const isExisting = options.some(option => inputValue === option.name)
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            name: `Otro "${inputValue}"`,
            lastNameFather: '',
            lastNameMother: '',
          })
        }

        return filtered
      }}
      getOptionLabel={option => {
        if (typeof option === 'string') {
          return option
        }
        if (option.inputValue) {
          return option.inputValue
        }
        return getDoctorFulName(option)
      }}
      renderOption={(props, option) => (
        <li {...props}>{getDoctorFulName(option)}</li>
      )}
      renderInput={params => (
        <TextField
          {...params}
          label="Médico/Solicitante"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

export default SelectDoctor
