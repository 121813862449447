import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Container, IconButton, Typography } from '@mui/material'

import DataGrid from '../../components/DataGrid/DataGrid'
import SubsidiaryReportModal from '../../components/SubsidiaryReportModal/SubsidiaryReportModal'

import RequestPageIcon from '@mui/icons-material/RequestPage'
import { setLoading, showSnackbar } from '../../actions/pageActions'
import { SUBSIDIARIES_PATH } from '../../constants/endpointsConstants'

import { client } from '../../utils/client'

const SubsidiaryList = () => {
  const dispatch = useDispatch()

  const [subsidiaries, setSubsidiaries] = useState([])
  const [loaderTable, setLoaderTable] = useState(false)
  const [subsidiary, setSubsidiary] = useState(null)
  const [openReportModal, setOpenReportModal] = useState(false)

  const columns = [
    { field: 'name', headerName: 'Nombre', flex: 0.2, minWidth: 200 },
    {
      field: 'address',
      headerName: 'Direccion',
      flex: 0.6,
      minWidth: 500,
    },
    {
      field: 'phone',
      headerName: 'Teléfono',
      flex: 0.2,
      minWidth: 100,
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      flex: 0.2,
      minWidth: 100,
      sortable: false,
      renderCell: params => (
        <>
          <IconButton
            color="primary"
            onClick={() => handleSelectSubsidiaryReport(params.row)}
          >
            <RequestPageIcon />
          </IconButton>
        </>
      ),
    },
  ]

  const handleLoading = value => {
    setLoaderTable(value)
    dispatch(setLoading(value))
  }

  const handleSelectSubsidiaryReport = selectedSubsidiary => {
    setSubsidiary(selectedSubsidiary)
    setOpenReportModal(true)
  }

  const handleCloseReportModal = () => {
    setOpenReportModal(false)
    setSubsidiary(null)
  }

  useEffect(() => {
    const getSubsidiaries = async () => {
      handleLoading(true)
      try {
        const { data } = await client.get(SUBSIDIARIES_PATH)
        setSubsidiaries(data?.data)
      } catch (error) {
        dispatch(showSnackbar())
      }
      handleLoading(false)
    }

    getSubsidiaries()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container maxWidth="xl">
      <Typography variant="h3" sx={{ marginBlock: '25px' }}>
        Lista de Sucursales
      </Typography>

      <DataGrid rows={subsidiaries} columns={columns} loading={loaderTable} />

      {openReportModal && (
        <SubsidiaryReportModal
          open={openReportModal}
          subsidiary={subsidiary}
          onClose={handleCloseReportModal}
        />
      )}
    </Container>
  )
}

export default SubsidiaryList
