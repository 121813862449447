import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useSelector } from 'react-redux'

import { COLUMN_NAMES } from '../../constants/inputsConstants'

const styles = {
  textField: {
    marginBlock: { sm: '10px', xs: '0px' },
  },
  button: {
    margin: '10px',
    textAlign: 'center',
  },
  grid: {
    marginBlock: { sm: '0px', xs: '10px' },
  },
  buttonGroup: {
    margin: '0 auto',
  },
}

const ERROR_MESSAGES = {
  name: 'Nombre requerido',
  suggestedCost: 'Precio Sugerido',
  areaId: 'Área requerido',
  helper: 'Texto ayuda requerido',
}

const defaultError = {
  name: {
    error: false,
    message: '',
  },
  suggestedCost: {
    error: false,
    message: '',
  },
  areaId: {
    error: false,
    message: '',
  },
  helper: {
    error: false,
    message: '',
  },
}

const emptyTest = {
  name: '',
  suggestedCost: '',
  areaId: '',
  helper: '',
}

const ModalTest = ({ open, onClose, onSubmit, test }) => {
  const initialTest = {
    name: test?.name || '',
    suggestedCost: test?.suggestedCost || '',
    areaId: test?.areaId || '',
    helper: test?.helper || '',
  }

  const [testFields, setTestFields] = useState(initialTest)
  const [validFields, setValidFields] = useState(defaultError)
  const [logicDelete, setLogicDelete] = useState(false)
  const [visibleColumns, setVisibleColumns] = useState(
    JSON.parse(test?.visibleColumns || '[]')?.map(column => column === 1)
  )
  const [isLoading, setIsLoading] = useState(false)

  const matches = useMediaQuery('(max-width:575px)')
  const sizeInput = matches ? 'small' : 'normal'
  const areas = useSelector(state => state.rootReducer.areas)

  const handleFieldChange = prop => event => {
    const {
      target: { value },
    } = event

    setTestFields({
      ...testFields,
      [prop]: value,
    })
  }

  const handleBlur = prop => event => {
    const { target } = event
    const value = target?.value.toString().trim()

    setValidFields({
      ...validFields,
      [prop]: {
        error: false,
        message: '',
      },
    })

    if (!value.length) {
      setValidFields({
        ...validFields,
        [prop]: {
          error: true,
          message: ERROR_MESSAGES[prop],
        },
      })
    } else {
      setTestFields({
        ...testFields,
        [prop]: value,
      })
    }
  }

  const disabledButton = () =>
    testFields.name &&
    testFields.suggestedCost &&
    testFields.areaId &&
    !isLoading

  const buildVisibleColumnsPayload = () =>
    `[${visibleColumns.map(column => (column ? '1' : '0'))}]`

  const handleSubmit = async () => {
    setIsLoading(true)
    if (
      await onSubmit(
        { ...testFields, visibleColumns: buildVisibleColumnsPayload() },
        logicDelete
      )
    ) {
      setTestFields(emptyTest)
      onClose()
    }
    setIsLoading(false)
  }

  const handleCloseModal = () => {
    onClose()
    setValidFields(defaultError)
  }

  const handleVisibleColumnChange = index => {
    const tempVisibleColumns = [...visibleColumns]
    tempVisibleColumns[index] = !tempVisibleColumns[index]
    setVisibleColumns(tempVisibleColumns)
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleCloseModal}
      closeAfterTransition
    >
      <DialogTitle sx={{ textAlign: 'center' }} variant="h6" component="h2">
        {test?.id ? 'Editar Análisis' : 'Crear Análisis'}
      </DialogTitle>
      <DialogContent>
        <Grid sx={styles.grid} container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              sx={styles.textField}
              size={sizeInput}
              error={validFields.name.error}
              fullWidth
              helperText={validFields.name.message}
              required
              label="Nombre"
              placeholder="Ingrese el nombre"
              value={testFields.name}
              onChange={handleFieldChange('name')}
              onBlur={handleBlur('name')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              sx={styles.textField}
              size={sizeInput}
              error={validFields.suggestedCost.error}
              fullWidth
              helperText={validFields.suggestedCost.message}
              required
              type="number"
              label="Precio Sugerido"
              placeholder="Ingrese el precio"
              value={testFields.suggestedCost}
              onChange={handleFieldChange('suggestedCost')}
              onBlur={handleBlur('suggestedCost')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <FormControl
              sx={styles.textField}
              error={validFields.areaId.error}
              fullWidth
              size={sizeInput}
            >
              <InputLabel>Área</InputLabel>
              <Select
                label="Área"
                value={testFields.areaId}
                onChange={handleFieldChange('areaId')}
                onBlur={handleBlur('areaId')}
              >
                {areas.map(area => (
                  <MenuItem key={area.id} value={area.id}>
                    {area.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{validFields.areaId.message}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              sx={styles.textField}
              size={sizeInput}
              fullWidth
              multiline
              label="Texto ayuda"
              placeholder="Ingrese el texto ayuda"
              value={testFields.helper}
              onChange={handleFieldChange('helper')}
              onBlur={handleBlur('helper')}
            />
          </Grid>
          {!!test?.id && (
            <Grid item xs={12} sm={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!logicDelete}
                    onChange={event => setLogicDelete(!event.target.checked)}
                  />
                }
                label="Aplicar cambios a las constultas ya creadas"
              />

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="visible-columns-content"
                  id="visible-columns-header"
                >
                  Columnas Visibles
                </AccordionSummary>
                <AccordionDetails>
                  <FormGroup>
                    {COLUMN_NAMES.map((column, index) => (
                      <FormControlLabel
                        key={column}
                        control={
                          <Switch
                            checked={visibleColumns[index]}
                            onChange={() => handleVisibleColumnChange(index)}
                          />
                        }
                        label={column}
                      />
                    ))}
                  </FormGroup>
                </AccordionDetails>
              </Accordion>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions sx={styles.buttonGroup}>
        <Button
          sx={styles.button}
          variant="outlined"
          color="primary"
          onClick={handleCloseModal}
        >
          Cancelar
        </Button>
        <Button
          sx={styles.button}
          variant="contained"
          color="primary"
          disabled={!disabledButton()}
          onClick={handleSubmit}
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ModalTest.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  test: PropTypes.instanceOf(Object),
}

ModalTest.defaultProps = {
  test: {},
}

export default ModalTest
