import * as React from 'react'
import Stack from '@mui/material/Stack'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import { useDispatch, useSelector } from 'react-redux'
import { hideSnackbar } from '../../actions/pageActions'

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export default function Snackbars() {
  const dispatch = useDispatch()
  const { openSnackBar, snackBarMessage, snackBarSeverity, snackBarDuration } =
    useSelector(state => state.pageReducer)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    dispatch(hideSnackbar())
  }

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      {openSnackBar && (
        <Snackbar
          open
          autoHideDuration={snackBarDuration}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={handleClose}
            severity={snackBarSeverity}
            sx={{ width: '100%' }}
          >
            {snackBarMessage}
          </Alert>
        </Snackbar>
      )}
    </Stack>
  )
}
