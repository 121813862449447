import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { client } from '../../utils/client'
import { Button, Container, IconButton, Typography } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import EditButton from '@mui/icons-material/Edit'
import RequestPageIcon from '@mui/icons-material/RequestPage'

import DataGrid from '../../components/DataGrid/DataGrid'
import CompanyModal from '../../components/CompanyModal/CompanyModal'
import { setLoading, showSnackbar } from '../../actions/pageActions'
import { COMPANIES_PATH } from '../../constants/endpointsConstants'
import DeleteCompany from '../../components/Company/Company'
import CompanyReportModal from '../../components/CompanyReportModal/CompanyReportModal'

const CompaniesList = () => {
  const dispatch = useDispatch()

  const [companies, setCompanies] = useState([])
  const [loaderTable, setLoaderTable] = useState(true)
  const [openModal, setOpenModal] = useState(false)
  const [openReportModal, setOpenReportModal] = useState(false)
  const [company, setCompany] = useState({})

  const columns = [
    { field: 'name', headerName: 'Nombre', flex: 0.7, minWidth: 100 },
    {
      field: 'firstNameManager',
      headerName: 'Nombre(s) Encargado',
      flex: 0.7,
      minWidth: 150,
    },
    {
      field: 'lastNameManager',
      headerName: 'Apellido(s) Encargado',
      flex: 0.7,
      minWidth: 150,
    },
    {
      field: 'phoneNumber',
      headerName: 'Teléfono',
      flex: 0.6,
      minWidth: 100,
    },
    {
      field: 'address',
      headerName: 'Dirección',
      flex: 0.9,
      minWidth: 150,
    },
    {
      field: 'notes',
      headerName: 'Notas',
      flex: 0.9,
      minWidth: 150,
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      flex: 0.6,
      minWidth: 150,
      sortable: false,
      renderCell: params => (
        <>
          <IconButton
            color="primary"
            onClick={() => handleSelectReportCompany(params.row)}
          >
            <RequestPageIcon />
          </IconButton>
          <IconButton
            color="primary"
            onClick={() => handleSelectCompany(params.row)}
          >
            <EditButton />
          </IconButton>
          <DeleteCompany company={params.row} onDelete={removeCompany} />
        </>
      ),
    },
  ]

  const handleLoading = value => {
    setLoaderTable(value)
    dispatch(setLoading(value))
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    setCompany({})
  }

  const handleCloseReportModal = () => {
    setOpenReportModal(false)
    setCompany({})
  }

  const handleSelectCompany = company => {
    setCompany(company)
    setOpenModal(true)
  }

  const handleSelectReportCompany = company => {
    setCompany(company)
    setOpenReportModal(true)
  }

  const removeCompany = companyId => {
    const newList = companies.filter(company => company.id !== companyId)
    setCompanies(newList)
  }

  const handleCreateCompany = async company => {
    let success = false
    handleLoading(true)

    try {
      const {
        data: { message, data: newCompany },
      } = await client.post(COMPANIES_PATH, company)
      setCompanies([newCompany, ...companies])
      dispatch(showSnackbar(message, 'success'))
      handleCloseModal()
      success = true
    } catch (error) {
      dispatch(showSnackbar(error?.response?.data?.message))
    }

    handleLoading(false)
    return success
  }

  const handleEditCompany = async companyPayload => {
    let success = false
    handleLoading(true)

    try {
      const {
        data: { message, data: updatedCompany },
      } = await client.put(`${COMPANIES_PATH}/${company?.id}`, companyPayload)

      const listUpdate = companies.map(item =>
        item.id === updatedCompany.id ? updatedCompany : item
      )
      setCompanies(listUpdate)
      dispatch(showSnackbar(message, 'success'))
      handleCloseModal()
      success = true
    } catch (error) {
      dispatch(showSnackbar(error?.response?.data?.message))
    }

    handleLoading(false)
    return success
  }

  useEffect(() => {
    handleLoading(true)
    client
      .get(COMPANIES_PATH)
      .then(response => {
        setCompanies(response.data.data)
        handleLoading(false)
      })
      .catch(error => {
        handleLoading(false)
        dispatch(showSnackbar())
      })
    // eslint-disable-next-line
  }, [])

  return (
    <Container maxWidth="xl">
      <Typography variant="h3" sx={{ marginBlock: '25px' }}>
        Lista de Empresas
      </Typography>
      <div
        style={{
          marginBottom: '10px',
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          variant="contained"
          startIcon={<AddCircleIcon />}
          onClick={() => setOpenModal(true)}
        >
          Agregar
        </Button>
      </div>
      <DataGrid rows={companies} columns={columns} loading={loaderTable} />

      {openModal && (
        <CompanyModal
          open={openModal}
          company={company}
          onClose={handleCloseModal}
          onSubmit={company?.id ? handleEditCompany : handleCreateCompany}
        />
      )}

      {openReportModal && (
        <CompanyReportModal
          open={openReportModal}
          company={company}
          onClose={handleCloseReportModal}
        />
      )}
    </Container>
  )
}

export default CompaniesList
