import React from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import { client } from '../../utils/client'
import { useDispatch } from 'react-redux'
import { setLoading, showSnackbar } from '../../actions/pageActions'
import { IconButton } from '@mui/material'
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog'
import { CONSULTATIONS_PATH } from '../../constants/endpointsConstants'

const DeleteConsultation = ({ consultation, onDelete }) => {
  const dispatch = useDispatch()

  const deleteConsultation = () => {
    dispatch(setLoading(true))
    client
      .delete(`${CONSULTATIONS_PATH}/${consultation.id}`)
      .then(resp => {
        dispatch(setLoading(false))
        dispatch(showSnackbar(resp.data.message, 'success'))
        onDelete(consultation.id)
      })
      .catch(() => {
        dispatch(setLoading(false))
        dispatch(showSnackbar())
      })
  }
  return (
    <ConfirmDialog
      buttonOpen={
        <IconButton color="error">
          <DeleteIcon />
        </IconButton>
      }
      title="Eliminar Consulta"
      body={`¿Deseas eliminar la consulta con código "C-${consultation.id}"?`}
      onConfirm={deleteConsultation}
    />
  )
}

export default DeleteConsultation
