import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Button,
  Container,
  IconButton,
  LinearProgress,
  Typography,
} from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import EditButton from '@mui/icons-material/Edit'

import CustomPaginationDataGrid from '../../components/CustomPaginationDataGrid/CustomPaginationDataGrid'
import ModalConsultation from '../../components/ModalConsultation/ModalConsultation'
import ModalConsultationDetail from '../../components/ModalConsultationDetail/ModalConsultationDetail'
import { SearchBar } from '../../components/SearchBar/SearchBar'

import { client } from '../../utils/client'
import { calculateProgress } from '../../utils/consultation'
import { setLoading, showSnackbar } from '../../actions/pageActions'

import { CONSULTATIONS_PATH } from '../../constants/endpointsConstants'
import { ADMIN, MANAGER } from '../../constants/rolesConstants'

import moment from 'moment'
import 'moment/locale/es'
import DeleteConsultation from '../../components/Consultation/DeleteConsultation'

const CONSULTATION_SEARCH_CRITERIA = [
  { label: 'Código', type: 'number', prop: 'id', startAdornment: 'C -' },
  { label: 'CI', type: 'number', prop: 'identityCard' },
  { label: 'Desde', type: 'date', prop: 'startDate' },
  { label: 'Hasta', type: 'date', prop: 'endDate' },
]

const ConsultationsList = props => {
  const dispatch = useDispatch()
  const loginUser = useSelector(state => state.userReducer.user)

  const [consultations, setConsultations] = useState([])
  const [loaderTable, setLoaderTable] = useState(true)
  const [openModal, setOpenModal] = useState(false)
  const [selectedConsultation, setSelectedConsultation] = useState({})
  const [openModalDetail, setOpenModalDetail] = useState(false)
  const [selectedConsultationDetail, setSelectedConsultationDetail] = useState(
    {}
  )
  const [page, setPage] = useState(0)
  const [totalConsultations, setTotalConsultations] = useState(0)
  const [limit, setLimit] = useState(10)
  const [searchFields, setSearchFields] = useState({})

  const removeConsultation = consultationId => {
    const newList = consultations.filter(
      consultation => consultation.id !== consultationId
    )
    setConsultations(newList)
    setTotalConsultations(totalConsultations - 1)
  }

  const columns = [
    {
      field: 'id',
      headerName: 'Código',
      flex: 0.6,
      minWidth: 80,
      valueGetter: params => `C-${params.row.id}`,
    },
    {
      field: 'date',
      headerName: 'Fecha',
      flex: 0.6,
      minWidth: 100,
      valueGetter: params => moment(params.row.date).format('L'),
    },
    {
      field: 'fullName',
      headerName: 'Nombre Completo',
      flex: 0.6,
      minWidth: 200,
      valueGetter: params =>
        `${params.row.patient?.name || ''} ${
          params.row.patient?.lastNameFather || ''
        } ${params.row.patient?.lastNameMother || ''}`,
    },
    {
      field: 'identityCard',
      headerName: 'CI',
      flex: 0.6,
      minWidth: 110,
      valueGetter: params => params.row.patient?.identityCard,
    },
    {
      field: 'status',
      headerName: 'Estado',
      flex: 0.6,
      minWidth: 150,
      renderCell: params => {
        const { testsCompleted, percentage } = calculateProgress(
          params.row.tests
        )
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
              <LinearProgress variant="determinate" value={percentage} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2" color="text.secondary">
                {testsCompleted}
              </Typography>
            </Box>
          </Box>
        )
      },
    },
    {
      field: 'subsidiary',
      headerName: 'Sucursal Origen',
      flex: 0.6,
      minWidth: 140,
      valueGetter: params => params.row.subsidiary?.name,
    },
    {
      field: 'userName',
      headerName: 'Atentido por',
      flex: 0.6,
      minWidth: 200,
      valueGetter: params =>
        `${params.row.user?.name || ''} ${
          params.row.user?.lastNameFather || ''
        } ${params.row.user?.lastNameMother || ''}`,
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      flex: 0.6,
      minWidth: 130,
      sortable: false,
      renderCell: params => (
        <>
          <IconButton
            color="primary"
            onClick={() => handleOpenDetail(params.row)}
          >
            <RemoveRedEyeIcon />
          </IconButton>
          {[ADMIN, MANAGER].includes(loginUser?.role?.name) && (
            <IconButton
              color="primary"
              onClick={() => handleSelectedConsultation(params.row)}
            >
              <EditButton />
            </IconButton>
          )}
          {loginUser?.role?.name === ADMIN && (
            <DeleteConsultation
              consultation={params.row}
              onDelete={removeConsultation}
            />
          )}
        </>
      ),
    },
  ]

  const handleLoading = value => {
    setLoaderTable(value)
    dispatch(setLoading(value))
  }

  const handleSelectedConsultation = consultation => {
    const tests = consultation.tests.map(test => ({
      id: test.id,
      name: test.name,
      cost: test.Test_Consultation.cost,
      comment: test.Test_Consultation.comment,
    }))
    const newConsultation = { ...consultation }
    newConsultation.tests = tests
    setSelectedConsultation(newConsultation)
    handleOpen()
  }

  const handleOpen = () => {
    setOpenModal(true)
  }

  const handleClose = () => {
    setOpenModal(false)
    setSelectedConsultation({})
  }

  const handleOpenDetail = consultation => {
    setSelectedConsultationDetail(consultation)
    setOpenModalDetail(true)
  }

  const handleCloseDetail = () => {
    setOpenModalDetail(false)
    setSelectedConsultationDetail({})
  }

  const handleCreateConsultation = async consultation => {
    let success = false
    handleLoading(true)

    try {
      const {
        data: { message, data: newConsultation },
      } = await client.post(CONSULTATIONS_PATH, consultation)
      setConsultations([newConsultation, ...consultations])
      setTotalConsultations(totalConsultations + 1)
      dispatch(showSnackbar(message, 'success'))
      success = true
      setSelectedConsultationDetail(newConsultation)
      setOpenModalDetail(true)
    } catch (error) {
      dispatch(showSnackbar(error?.response?.data?.message))
    }

    handleLoading(false)
    return success
  }

  const handleEditConsultation = async consultation => {
    let success = false
    const consultationToEdit = {
      patientId: consultation.patientId,
      subsidiaryId: consultation.subsidiaryId,
      companyId: consultation.companyId,
      assuredId: consultation.assuredId,
      doctorId: consultation.doctorId,
      tests: consultation.tests,
      requester: consultation.requester,
    }
    handleLoading(true)

    try {
      const {
        data: { message, data: updatedConsultation },
      } = await client.put(
        `${CONSULTATIONS_PATH}/${consultation?.id}`,
        consultationToEdit
      )

      dispatch(showSnackbar(message, 'success'))
      const listUpdate = consultations.map(item =>
        item.id === updatedConsultation.id ? updatedConsultation : item
      )
      setConsultations(listUpdate)
      handleClose()
      success = true
    } catch (error) {
      dispatch(showSnackbar(error?.response?.data?.message))
    }

    handleLoading(false)
    return success
  }

  const handleSearch = values => setSearchFields(values)

  const getConsultations = async () => {
    handleLoading(true)
    try {
      const { data } = await client.get(CONSULTATIONS_PATH, {
        params: {
          limit,
          page: page + 1,
          ...searchFields,
        },
      })
      setConsultations(data?.data)
      setTotalConsultations(data.total)
    } catch (error) {
      dispatch(showSnackbar())
    }
    handleLoading(false)
  }

  useEffect(() => {
    getConsultations()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page, searchFields])

  return (
    <Container maxWidth="xl">
      <Typography variant="h3" sx={{ marginBlock: '25px' }}>
        Lista de Consultas
      </Typography>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          sx={{ marginBlock: '10px' }}
          variant="contained"
          startIcon={<AddCircleIcon />}
          onClick={handleOpen}
        >
          Agregar
        </Button>
      </div>
      <SearchBar onSubmit={handleSearch} items={CONSULTATION_SEARCH_CRITERIA} />
      <CustomPaginationDataGrid
        columns={columns}
        items={consultations}
        limit={limit}
        loading={loaderTable}
        page={page}
        totalItems={totalConsultations}
        setPage={setPage}
        setLimit={setLimit}
      />

      {openModal && (
        <ModalConsultation
          open={openModal}
          consultation={selectedConsultation}
          onClose={handleClose}
          onSubmit={
            selectedConsultation?.id
              ? handleEditConsultation
              : handleCreateConsultation
          }
        />
      )}
      {openModalDetail && (
        <ModalConsultationDetail
          open={openModalDetail}
          consultationId={selectedConsultationDetail.id}
          onClose={handleCloseDetail}
          onSaveResult={async () => getConsultations()}
        />
      )}
    </Container>
  )
}

export default ConsultationsList
