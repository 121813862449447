import { Container, Typography } from '@mui/material'
import React from 'react'
import SettingTestsByArea from '../../components/SettingTestsByArea/SettingTestsByArea'

const Settings = () => (
  <Container maxWidth="xl">
    <Typography variant="h3" sx={{ marginBlock: '25px' }}>
      Ajustes
    </Typography>
    <SettingTestsByArea />
  </Container>
)

export default Settings
