import { configureStore } from '@reduxjs/toolkit'

import pageReducer from './slices/pageReducer'
import rootReducer from './slices/rootReducer'
import userReducer from './slices/userReducer'

const reducer = {
  pageReducer,
  rootReducer,
  userReducer,
}

const store = configureStore({
  reducer,
})

export default store
