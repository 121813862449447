import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import useMediaQuery from '@mui/material/useMediaQuery'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Select,
  Switch,
  MenuItem,
  TextField,
} from '@mui/material'

import { validateEmail } from '../../utils/auth'

import { ADMIN } from '../../constants/rolesConstants'

const styles = {
  textField: {
    marginBlock: { sm: '10px', xs: '0px' },
  },
  buttonGroup: {
    margin: '0 auto',
  },
  button: {
    margin: '10px',
  },
  grid: {
    marginBlock: { sm: '0px', xs: '10px' },
  },
}

const defaultError = {
  email: {
    error: false,
    message: '',
  },
  name: {
    error: false,
    message: '',
  },
  lastNameFather: {
    error: false,
    message: '',
  },
  lastNameMother: {
    error: false,
    message: '',
  },
  identityCard: {
    error: false,
    message: '',
  },
  phone: {
    error: false,
    message: '',
  },
}

const ERROR_MESSAGES = {
  email: 'Email requerido',
  name: 'Nombre requerido',
  lastNameFather: 'Apellido paterno requerido',
  lastNameMother: 'Apellido materno requerido',
  identityCard: 'CI requerido',
  phone: 'Numero de celular requerido',
}

const emptyUser = {
  email: '',
  name: '',
  lastNameFather: '',
  lastNameMother: '',
  identityCard: '',
  phone: '',
  subsidiaryId: '',
  roleId: '',
}

const ModalUser = ({ open, user, onClose, onSubmit }) => {
  const initialUser = {
    email: user?.email || '',
    name: user?.name || '',
    lastNameFather: user?.lastNameFather || '',
    lastNameMother: user?.lastNameMother || '',
    identityCard: user?.identityCard || '',
    phone: user?.phone || '',
    subsidiaryId: user?.subsidiary?.id || '',
    roleId: user?.role?.id || '',
    resetPassword: false,
  }

  const roles = useSelector(state => state.rootReducer.roles)
  const subsidiaries = useSelector(state => state.rootReducer.subsidiaries)
  const loginUserRole = useSelector(state => state.userReducer.user.role)
  const isLoading = useSelector(state => state.pageReducer.isLoading)

  const [userFields, setUserFields] = useState(initialUser)
  const [validFields, setValidFields] = useState(defaultError)

  const matches = useMediaQuery('(max-width:575px)')
  const sizeInput = matches ? 'small' : 'normal'

  const handleFieldChange = prop => event => {
    const {
      target: { value, checked },
    } = event

    setUserFields({
      ...userFields,
      [prop]: prop === 'resetPassword' ? checked : value,
    })
  }

  const handleBlur = prop => event => {
    const { target } = event

    const value = target?.value.trim()

    setValidFields({
      ...validFields,
      [prop]: {
        error: false,
        message: '',
      },
    })

    if (!value.length) {
      setValidFields({
        ...validFields,
        [prop]: {
          error: true,
          message: ERROR_MESSAGES[prop],
        },
      })
    } else {
      if (prop === 'email' && !validateEmail(value)) {
        setValidFields({
          ...validFields,
          [prop]: {
            error: true,
            message: 'Ingrese un email valido',
          },
        })
      }

      setUserFields({
        ...userFields,
        [prop]: value,
      })
    }
  }

  const disabledButton = () =>
    userFields.name &&
    userFields.email &&
    validateEmail(userFields.email) &&
    userFields.lastNameFather &&
    userFields.lastNameMother &&
    userFields.identityCard &&
    userFields.phone && !isLoading

  const handleCloseModal = () => {
    onClose()
    setUserFields(emptyUser)
    setValidFields(defaultError)
  }

  const handleClickButton = async () => {
    if (await onSubmit(userFields)) {
      setUserFields(emptyUser)
    }
  }

  const isAdmin = loginUserRole.name === ADMIN
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <DialogTitle sx={{ textAlign: 'center' }} variant="h6" component="h2">
        {user.id ? 'Editar Usuario' : 'Crear Usuario'}
      </DialogTitle>
      <DialogContent>
        <Grid sx={styles.grid} container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              sx={styles.textField}
              size={sizeInput}
              error={validFields.name.error}
              fullWidth
              helperText={validFields.name.message}
              required
              label="Nombre"
              placeholder="Ingrese el nombre"
              value={userFields.name}
              onChange={handleFieldChange('name')}
              onBlur={handleBlur('name')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              sx={styles.textField}
              size={sizeInput}
              error={validFields.email.error}
              fullWidth
              helperText={validFields.email.message}
              required
              label="Email"
              placeholder="Ingrese el email"
              value={userFields.email}
              onChange={handleFieldChange('email')}
              onBlur={handleBlur('email')}
            />
          </Grid>
        </Grid>

        <Grid sx={styles.grid} container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              sx={styles.textField}
              size={sizeInput}
              error={validFields.lastNameFather.error}
              fullWidth
              helperText={validFields.lastNameFather.message}
              required
              label="Apellido Paterno"
              placeholder="Ingrese el apellido"
              value={userFields.lastNameFather}
              onChange={handleFieldChange('lastNameFather')}
              onBlur={handleBlur('lastNameFather')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              sx={styles.textField}
              size={sizeInput}
              error={validFields.lastNameMother.error}
              fullWidth
              helperText={validFields.lastNameMother.message}
              required
              label="Apellido Materno"
              placeholder="Ingrese el apellido"
              value={userFields.lastNameMother}
              onChange={handleFieldChange('lastNameMother')}
              onBlur={handleBlur('lastNameMother')}
            />
          </Grid>
        </Grid>

        <Grid sx={styles.grid} container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              sx={styles.textField}
              type="number"
              size={sizeInput}
              error={validFields.phone.error}
              fullWidth
              helperText={validFields.phone.message}
              required
              label="Celular"
              placeholder="Ingrese celular"
              value={userFields.phone}
              onChange={handleFieldChange('phone')}
              onBlur={handleBlur('phone')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              sx={styles.textField}
              type="number"
              size={sizeInput}
              error={validFields.identityCard.error}
              fullWidth
              helperText={validFields.identityCard.message}
              required
              label="CI"
              placeholder="Ingrese el CI"
              value={userFields.identityCard}
              onChange={handleFieldChange('identityCard')}
              onBlur={handleBlur('identityCard')}
            />
          </Grid>
        </Grid>
        {isAdmin && (
          <Grid sx={styles.grid} container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl sx={styles.textField} fullWidth size={sizeInput}>
                <InputLabel>Sucursal</InputLabel>
                <Select
                  label="Sucursal"
                  value={userFields.subsidiaryId || subsidiaries[0]?.id}
                  onChange={handleFieldChange('subsidiaryId')}
                >
                  {subsidiaries.map(subsidiary => (
                    <MenuItem key={subsidiary.id} value={subsidiary.id}>
                      {subsidiary.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl sx={styles.textField} fullWidth size={sizeInput}>
                <InputLabel>Rol</InputLabel>
                <Select
                  label="Rol"
                  value={userFields.roleId || roles[0]?.id}
                  onChange={handleFieldChange('roleId')}
                >
                  {roles.map(rol => (
                    <MenuItem key={rol.name} value={rol.id}>
                      {rol.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}
        {!isEmpty(user) && (
          <FormControlLabel
            control={
              <Switch
                checked={userFields.resetPassword}
                onChange={handleFieldChange('resetPassword')}
              />
            }
            label="Restablecer Contraseña"
          />
        )}
      </DialogContent>
      <DialogActions sx={styles.buttonGroup}>
        <Button
          sx={styles.button}
          variant="outlined"
          color="primary"
          onClick={handleCloseModal}
        >
          Cancelar
        </Button>
        <Button
          sx={styles.button}
          variant="contained"
          color="primary"
          disabled={!disabledButton()}
          onClick={handleClickButton}
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ModalUser.propTypes = {
  open: PropTypes.bool.isRequired,
  user: PropTypes.instanceOf(Object),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

ModalUser.defaultProps = {
  user: {},
}

export default ModalUser
