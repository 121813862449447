import { decode } from 'jsonwebtoken'

export const decodeToken = token => {
  return decode(token)
}

export const validateEmail = email => {
  const expressionRegular =
    /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i
  return expressionRegular.test(email)
}
