import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import useMediaQuery from '@mui/material/useMediaQuery'

import {
  Box,
  Container,
  IconButton,
  LinearProgress,
  Typography,
} from '@mui/material'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'

import CustomPaginationDataGrid from '../../components/CustomPaginationDataGrid/CustomPaginationDataGrid'
import ModalConsultationDetail from '../../components/ModalConsultationDetail/ModalConsultationDetail'

import { client } from '../../utils/client'
import { calculateProgress } from '../../utils/consultation'
import { formatBirthday, getBirthday } from '../../utils/patient'

import { setLoading, showSnackbar } from '../../actions/pageActions'

import { PATIENT_CONSULTATIONS_PATH } from '../../constants/endpointsConstants.js'

import moment from 'moment'
import 'moment/locale/es'

const PatientConsultations = () => {
  const { patientId } = useParams()
  const dispatch = useDispatch()
  const matches = useMediaQuery('(max-width:575px)')

  const styles = {
    fieldset: {
      display: `${!matches ? 'flex' : 'block'}`,
      marginBlock: '15px',
      borderRadius: '8px',
      border: '1px solid rgba(0, 0, 0, 0.12)',
      minHeight: '95%',
    },
    div: {
      flex: 1,
    },
  }

  const [consultations, setConsultations] = useState([])
  const [totalConsultations, setTotalConsultations] = useState(0)
  const [loaderTable, setLoaderTable] = useState(true)
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(0)
  const [patient, setPatient] = useState({})
  const [openModalDetail, setOpenModalDetail] = useState(false)
  const [selectedConsultation, setSelectedConsultation] = useState({})

  const agePatient = getBirthday(patient?.birthday)

  const columns = [
    {
      field: 'id',
      headerName: 'Código',
      flex: 0.6,
      minWidth: 80,
      valueGetter: params => `C-${params.row.id}`,
    },
    {
      field: 'date',
      headerName: 'Fecha',
      flex: 0.6,
      minWidth: 100,
      valueGetter: params => moment(params.row.date).format('L'),
    },
    {
      field: 'fullName',
      headerName: 'Nombre Completo',
      flex: 0.6,
      minWidth: 200,
      valueGetter: params =>
        `${params.row.patient?.name || ''} ${
          params.row.patient?.lastNameFather || ''
        } ${params.row.patient?.lastNameMother || ''}`,
    },
    {
      field: 'identityCard',
      headerName: 'CI',
      flex: 0.6,
      minWidth: 110,
      valueGetter: params => params.row.patient?.identityCard,
    },
    {
      field: 'status',
      headerName: 'Estado',
      flex: 0.6,
      minWidth: 150,
      renderCell: params => {
        const { testsCompleted, percentage } = calculateProgress(
          params.row.tests
        )
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
              <LinearProgress variant="determinate" value={percentage} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2" color="text.secondary">
                {testsCompleted}
              </Typography>
            </Box>
          </Box>
        )
      },
    },
    {
      field: 'subsidiary',
      headerName: 'Sucursal Origen',
      flex: 0.6,
      minWidth: 140,
      valueGetter: params => params.row.subsidiary?.name,
    },
    {
      field: 'userName',
      headerName: 'Atentido por',
      flex: 0.6,
      minWidth: 200,
      valueGetter: params =>
        `${params.row.user?.name || ''} ${
          params.row.user?.lastNameFather || ''
        } ${params.row.user?.lastNameMother || ''}`,
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      flex: 0.6,
      minWidth: 100,
      sortable: false,
      renderCell: params => (
        <IconButton
          color="secondary"
          onClick={() => handleOpenDetail(params.row)}
        >
          <RemoveRedEyeIcon />
        </IconButton>
      ),
    },
  ]

  const handleOpenDetail = consultation => {
    setSelectedConsultation(consultation)
    setOpenModalDetail(true)
  }

  const handleCloseDetail = () => {
    setOpenModalDetail(false)
    setSelectedConsultation({})
  }

  const handleLoading = value => {
    setLoaderTable(value)
    dispatch(setLoading(value))
  }

  const getPatientConsultations = async () => {
    handleLoading(true)
    try {
      const { data } = await client.get(PATIENT_CONSULTATIONS_PATH(patientId), {
        params: {
          limit,
          page: page + 1,
        },
      })
      setConsultations(data?.data?.consultations)
      setTotalConsultations(data.total)
      setPatient(data?.data?.patient)
    } catch (error) {
      dispatch(showSnackbar())
    }
    handleLoading(false)
  }

  useEffect(() => {
    getPatientConsultations()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId, limit, page])

  return (
    <Container maxWidth="xl" sx={{ marginBlock: '25px' }}>
      <Typography variant="h3">Consultas Por Paciente</Typography>

      <fieldset style={styles.fieldset}>
        <legend>
          <Typography variant="body1">Paciente</Typography>
        </legend>
        <div style={styles.div}>
          <Typography variant="body1">
            <b>Nombre(s):</b> {patient?.name}
          </Typography>
          <Typography variant="body1">
            <b>Apellido(s):</b>
            {` ${patient?.lastNameFather || ''} 
                  ${patient?.lastNameMother || ''}`}
          </Typography>
          <Typography variant="body1">
            <b>CI:</b> {patient?.identityCard}
          </Typography>
          <Typography variant="body1">
            <b>Teléfono:</b> {patient?.landline}
          </Typography>
        </div>

        <div style={styles.div}>
          <Typography variant="body1">
            <b>Celular:</b> {patient?.cellPhone}
          </Typography>
          <Typography variant="body1">
            <b>Fecha de Nacimiento:</b>
            {formatBirthday(patient?.birthday, agePatient)}
          </Typography>
          <Typography variant="body1">
            <b>Correo:</b> {patient?.email}
          </Typography>
          <Typography variant="body1">
            <b>Género:</b> {patient?.gender === 'M' ? 'Masculino' : 'Femenino'}
          </Typography>
        </div>
      </fieldset>

      <CustomPaginationDataGrid
        columns={columns}
        items={consultations}
        limit={limit}
        loading={loaderTable}
        page={page}
        totalItems={totalConsultations}
        setPage={setPage}
        setLimit={setLimit}
      />

      {openModalDetail && (
        <ModalConsultationDetail
          open={openModalDetail}
          consultationId={selectedConsultation.id}
          onClose={handleCloseDetail}
          onSaveResult={async () => getPatientConsultations()}
        />
      )}
    </Container>
  )
}

export default PatientConsultations
