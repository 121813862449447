import React from 'react'
import PropTypes from 'prop-types'
import { Container } from '@mui/material'

const LayoutLogin = ({ children }) => {
  return (
    <Container
      maxWidth="md"
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {children}
    </Container>
  )
}

LayoutLogin.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutLogin
