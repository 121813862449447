import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import ReorderIcon from '@mui/icons-material/Reorder'
import { pick } from 'lodash'
import { sortableContainer, sortableElement } from 'react-sortable-hoc'
import { useDispatch } from 'react-redux'

import { setLoading, showSnackbar } from '../../actions/pageActions.js'
import { AREAS_PATH } from '../../constants/endpointsConstants.js'
import { client } from '../../utils/client.js'
import { arrayMove } from '../../utils/typeResults.js'
import { fetchAreas } from '../../actions/rootActions.js'

const styles = {
  buttonGroup: {
    margin: '0 auto',
  },
  button: {
    margin: '10px',
  },
  listItem: {
    zIndex: 2000,
  },
}

const SortableContainer = sortableContainer(({ children }) => (
  <List>{children}</List>
))

const SortableItem = sortableElement(({ item }) => (
  <ListItem sx={styles.listItem}>
    <ListItemIcon>
      <ReorderIcon />
    </ListItemIcon>
    <ListItemText primary={item.name} />
  </ListItem>
))

const SortTestsModal = ({ open, tests, areaId, onClose }) => {
  const dispatch = useDispatch()
  const [testsList, setTestsList] = useState(tests)

  const onSortEnd = ({ oldIndex, newIndex }) =>
    setTestsList(arrayMove(testsList, oldIndex, newIndex))

  const handleSaveOrder = async () => {
    dispatch(setLoading(true))
    try {
      const formattedTests = testsList.map(typeResult =>
        pick(typeResult, 'id', 'name')
      )
      const {
        data: { message },
      } = await client.put(`${AREAS_PATH}/${areaId}/updateTestsOrder`, {
        tests: formattedTests,
      })
      dispatch(showSnackbar(message, 'success'))
      dispatch(fetchAreas())
      onClose(testsList)
    } catch (error) {
      dispatch(showSnackbar(error?.response?.data?.message))
    }
    dispatch(setLoading(false))
  }

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <DialogTitle sx={{ textAlign: 'center' }} variant="h6" component="h2">
        Ordenar Análisis
      </DialogTitle>
      <DialogContent sx={{ height: '400px', overflowY: 'auto' }}>
        <SortableContainer onSortEnd={onSortEnd}>
          {testsList.map((item, index) => (
            <SortableItem key={`${item.id}`} index={index} item={item} />
          ))}
        </SortableContainer>
      </DialogContent>
      <DialogActions sx={styles.buttonGroup}>
        <Button
          sx={styles.button}
          variant="outlined"
          color="primary"
          onClick={onClose}
        >
          Cancelar
        </Button>
        <Button
          sx={styles.button}
          variant="contained"
          color="primary"
          onClick={handleSaveOrder}
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

SortTestsModal.propTypes = {
  open: PropTypes.bool,
  tests: PropTypes.instanceOf(Array),
  areaId: PropTypes.number.isRequired,
  onClose: PropTypes.func,
}

SortTestsModal.defaultProps = {
  open: false,
  tests: [],
  onClose: () => {},
}

export default SortTestsModal
