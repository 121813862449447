export const INPUTS_TYPE = {
  number: {
    keyboardType: 'number',
    label: 'Numérico',
  },
  float: {
    keyboardType: 'number',
    label: 'Decimales',
  },
  string: {
    keyboardType: 'string',
    label: 'Texto',
  },
  selection: {
    keyboardType: 'selection',
    label: 'Selección',
  },
}

export const COLUMN_NAMES = ['Prueba', 'Resultado', 'Rango Ref.', 'Método']
