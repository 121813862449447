import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  Paper,
  TextField,
  Typography,
  OutlinedInput,
  InputAdornment,
} from '@mui/material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import { login } from '../../actions/userActions'
import logo from '../../assets/logo.png'

const TITLE = 'Iniciar Sesión'
const MESSAGE_ERROR_USER = 'Ingrese su nombre de usuario por favor'
const MESSAGE_ERROR_PASSWORD = 'Ingrese su contraseña por favor'

const defaultError = {
  user: {
    error: false,
    message: '',
  },
  password: {
    error: false,
    message: '',
  },
}

const styles = {
  paper: { height: '560px', width: '500px', textAlign: 'center' },
  title: { marginTop: '50px', fontWeight: 700 },
  input: { width: '80%', margin: '20px' },
  button: { marginTop: '50px', width: '120px' },
  logo: { marginTop: '15px' },
  errorMessage: {
    textAlign: 'center',
    color: '#d32f2f',
  },
}

const Login = () => {
  const [credentials, setCredentials] = useState({
    user: '',
    password: '',
  })
  const [inputValid, setInputValid] = useState(defaultError)
  const [showPassword, setShowPassword] = useState(false)

  const dispatch = useDispatch()

  const errorMessage = useSelector(state => state.userReducer.errorMessage)
  const isLoading = useSelector(state => state.userReducer.isLoading)

  const handleChangeInput = prop => event => {
    const {
      target: { value },
    } = event

    setCredentials({
      ...credentials,
      [prop]: value.trim(),
    })
  }

  const handleBlurInput = prop => event => {
    const {
      target: { value },
    } = event

    setInputValid({
      ...inputValid,
      [prop]: {
        error: false,
        message: '',
      },
    })

    if (!value.trim().length) {
      setInputValid({
        ...inputValid,
        [prop]: {
          error: true,
          message:
            prop === 'user' ? MESSAGE_ERROR_USER : MESSAGE_ERROR_PASSWORD,
        },
      })
    }
  }
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const disabledButton = () => {
    const { user, password } = credentials
    return user.length && password.length
  }

  const handleLogin = () => {
    dispatch(login(credentials))
  }

  const handlePressEnter = event => {
    if (event.key === 'Enter' && disabledButton()) {
      handleLogin()
    }
  }

  return isLoading ? (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
  ) : (
    <Paper
      className="login-content"
      sx={styles.paper}
      elevation={3}
      onKeyDown={handlePressEnter}
    >
      <Typography variant="h4" sx={styles.title}>
        {TITLE}
      </Typography>

      <img src={logo} style={styles.logo} alt="Logo" />

      <TextField
        sx={styles.input}
        error={inputValid.user.error}
        label="Usuario"
        required
        helperText={inputValid.user.message}
        value={credentials.user}
        onChange={handleChangeInput('user')}
        onBlur={handleBlurInput('user')}
      />

      <FormControl sx={styles.input} variant="outlined">
        <InputLabel required error={inputValid.password.error}>
          Contraseña
        </InputLabel>
        <OutlinedInput
          type={showPassword ? 'text' : 'password'}
          error={inputValid.password.error}
          label="Contraseña"
          required
          value={credentials.password}
          onChange={handleChangeInput('password')}
          onBlur={handleBlurInput('password')}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        <FormHelperText error={inputValid.password.error}>
          {inputValid.password.message}
        </FormHelperText>
      </FormControl>

      {errorMessage && (
        <Typography sx={styles.errorMessage}>{errorMessage}</Typography>
      )}

      <Button
        sx={styles.button}
        variant="contained"
        color="primary"
        disabled={!disabledButton()}
        onClick={handleLogin}
      >
        Iniciar
      </Button>
    </Paper>
  )
}

export default Login
