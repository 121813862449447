import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'

import ScrollToHereOnMount from '../../../common/ScrollToHereOnMount/ScrollToHereOnMount'
import SelectPatient from '../../SelectPatient/SelectPatient'

const styles = {
  fieldName: {
    maxHeight: '25px',
    fontWeight: 800,
  },
  fieldValue: {
    maxHeight: '25px',
    fontWeight: 600,
  },
  patientData: {
    padding: { sm: '10px 50px', xs: '10px 0' },
  },
}

const Step3Optional = ({
  assureds,
  setAssureds,
  patient,
  onChangeStep3Optional,
  company,
}) => {
  const matches = useMediaQuery('(max-width:575px)')
  return (
    <div
      style={{ padding: `${matches ? '20px 0' : '20px'}`, overflowY: 'clip' }}
    >
      <ScrollToHereOnMount />
      <div className="patient-info">
        <h3 style={{ marginBlock: 0 }}>DATOS PACIENTE: </h3>
        <Grid container spacing={2} sx={styles.patientData}>
          <Grid container spacing={1} item xs={12} sm={8} md={8}>
            <Grid item xs={6} sm={6} md={6}>
              <h4 style={styles.fieldName}>Nombre Completo:</h4>
              <h4 style={styles.fieldName}>CI:</h4>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <h4 style={styles.fieldValue}>
                {`${patient?.name || ''} ${patient?.lastNameFather || ''} ${
                  patient?.lastNameMother || ''
                }`}
              </h4>
              <h4 style={styles.fieldValue}>{patient?.identityCard}</h4>
            </Grid>
          </Grid>
        </Grid>
        <h3 style={{ marginTop: 0, marginBottom: 20 }}>
          Seleccione el titular para la empresa: {company?.name}
        </h3>
      </div>
      <SelectPatient
        patients={assureds}
        setPatients={setAssureds}
        onChangeSelection={onChangeStep3Optional}
      />
    </div>
  )
}

Step3Optional.propTypes = {
  assureds: PropTypes.instanceOf(Array),
  setAssureds: PropTypes.func,
  onChangeStep3Optional: PropTypes.func.isRequired,
  patient: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
}

Step3Optional.defaultProps = {
  assureds: [],
  setAssureds: () => {},
}

export default Step3Optional
