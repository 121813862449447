import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import { esES as coreEsEs } from '@mui/material/locale'
import { esES } from '@mui/x-data-grid'

const theme = responsiveFontSizes(
  createTheme(
    {
      palette: {
        primary: {
          main: '#006EB6',
        },
        secondary: {
          main: '#DF0A0E',
        },
      },
    },
    esES,
    coreEsEs
  )
)

export default theme
