import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { showSnackbar } from '../../actions/pageActions'
import { COMPANIES_PATH } from '../../constants/endpointsConstants'
import { client } from '../../utils/client'

const SelectCompany = ({ onCompanySelected, defaultCompany }) => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [companies, setCompanies] = useState(
    defaultCompany ? [defaultCompany] : []
  )

  const [companySelected, setCompanySelected] = useState(
    defaultCompany?.id || ''
  )

  const handleOpenSelect = async () => {
    if (companies.length > 1) return

    setLoading(true)
    try {
      const {
        data: { data: companiesResult },
      } = await client.get(COMPANIES_PATH)
      setCompanies(companiesResult)
    } catch (error) {
      dispatch(showSnackbar(error?.response?.data?.message))
    }
    setLoading(false)
  }
  const handleFieldChange = event => {
    const {
      target: { value },
    } = event

    setCompanySelected(value)
    const company = companies.find(company => company.id === value)
    onCompanySelected(company)
  }
  return (
    <FormControl fullWidth>
      <InputLabel>Empresa</InputLabel>
      <Select
        value={companySelected}
        label="Empresa"
        onChange={handleFieldChange}
        onOpen={handleOpenSelect}
        defaultValue=""
      >
        <MenuItem disabled={loading} value="">
          <em>{loading ? 'Cargando...' : 'Ninguno'}</em>
        </MenuItem>
        {companies.map(company => (
          <MenuItem key={company.id} value={company.id}>
            {company.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default SelectCompany
