import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import useMediaQuery from '@mui/material/useMediaQuery'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from '@mui/material'

const styles = {
  textField: {
    marginBlock: { sm: '10px', xs: '0px' },
  },
  buttonGroup: {
    margin: '0 auto',
  },
  button: {
    margin: '10px',
  },
  grid: {
    marginBlock: { sm: '0px', xs: '10px' },
  },
}

const emptyDoctor = {
  name: '',
  lastNameFather: '',
  lastNameMother: '',
  discount: undefined,
  medicalCenter: '',
  phoneNumber: undefined,
  notes: '',
}

const defaultError = {
  name: {
    error: false,
    message: '',
  },
  lastNameFather: {
    error: false,
    message: '',
  },
  lastNameMother: {
    error: false,
    message: '',
  },
  discount: {
    error: false,
    message: '',
  },
  medicalCenter: {
    error: false,
    message: '',
  },
  phoneNumber: {
    error: false,
    message: '',
  },
  notes: {
    error: false,
    message: '',
  },
}

const ERROR_MESSAGES = {
  name: 'Nombre requerido',
  lastNameFather: 'Apellido paterno requerido',
  lastNameMother: 'Apellido materno requerido',
}

const DoctorModal = ({ open, doctor, onClose, onSubmit }) => {
  const initialDoctor = {
    name: doctor?.name || '',
    lastNameFather: doctor?.lastNameFather || '',
    lastNameMother: doctor?.lastNameMother || '',
    discount: doctor?.discount || undefined,
    medicalCenter: doctor?.medicalCenter || '',
    phoneNumber: doctor?.phoneNumber || undefined,
    notes: doctor?.notes || '',
  }
  const [doctorFields, setDoctorFields] = useState(initialDoctor)
  const [validFields, setValidFields] = useState(defaultError)

  const matches = useMediaQuery('(max-width:575px)')
  const sizeInput = matches ? 'small' : 'normal'

  const handleCloseModal = () => {
    setDoctorFields(emptyDoctor)
    onClose()
  }

  const handleFieldChange = prop => event => {
    const {
      target: { value },
    } = event

    if (prop === 'phoneNumber') {
      if (Number(value) >= 0) {
        setDoctorFields({
          ...doctorFields,
          [prop]: value,
        })
      }
    } else {
      setDoctorFields({
        ...doctorFields,
        [prop]: value,
      })
    }
  }

  const handleBlur = prop => event => {
    const { target } = event

    const value = target?.value.trim()

    setValidFields({
      ...validFields,
      [prop]: {
        error: false,
        message: '',
      },
    })

    if (!value.length) {
      setValidFields({
        ...validFields,
        [prop]: {
          error: true,
          message: ERROR_MESSAGES[prop],
        },
      })
    }
  }

  const disabledButton = useMemo(
    () =>
      !(
        doctorFields.name?.trim() &&
        doctorFields.lastNameFather?.trim() &&
        doctorFields.lastNameMother?.trim()
      ),
    [
      doctorFields.name,
      doctorFields.lastNameFather,
      doctorFields.lastNameMother,
    ]
  )

  const handleSubmit = async () => {
    if (await onSubmit(doctorFields)) {
      setDoctorFields(emptyDoctor)
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <DialogTitle sx={{ textAlign: 'center' }} variant="h6" component="h2">
        {doctor.id ? 'Editar Doctor' : 'Crear Doctor'}
      </DialogTitle>
      <DialogContent>
        <Grid sx={styles.grid} container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              sx={styles.textField}
              size={sizeInput}
              error={validFields.name.error}
              fullWidth
              helperText={validFields.name.message}
              required
              label="Nombre"
              placeholder="Ingrese el nombre"
              value={doctorFields.name}
              onChange={handleFieldChange('name')}
              onBlur={handleBlur('name')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              sx={styles.textField}
              size={sizeInput}
              error={validFields.lastNameFather.error}
              fullWidth
              helperText={validFields.lastNameFather.message}
              required
              label="Apellido paterno"
              placeholder="Ingrese el apellido paterno"
              value={doctorFields.lastNameFather}
              onChange={handleFieldChange('lastNameFather')}
              onBlur={handleBlur('lastNameFather')}
            />
          </Grid>
        </Grid>
        <Grid sx={styles.grid} container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              sx={styles.textField}
              size={sizeInput}
              error={validFields.lastNameMother.error}
              fullWidth
              helperText={validFields.lastNameMother.message}
              required
              label="Apellidos materno"
              placeholder="Ingrese el apellido materno"
              value={doctorFields.lastNameMother}
              onChange={handleFieldChange('lastNameMother')}
              onBlur={handleBlur('lastNameMother')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              sx={styles.textField}
              size={sizeInput}
              fullWidth
              label="Centro Médico"
              placeholder="Ingrese el centro Médico"
              value={doctorFields.medicalCenter}
              onChange={handleFieldChange('medicalCenter')}
            />
          </Grid>
        </Grid>
        <Grid sx={styles.grid} container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              sx={styles.textField}
              type="number"
              size={sizeInput}
              fullWidth
              label="Celular"
              placeholder="Ingrese el celular"
              value={doctorFields.phoneNumber}
              onChange={handleFieldChange('phoneNumber')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              sx={styles.textField}
              size={sizeInput}
              fullWidth
              label="Notas"
              placeholder="Ingrese alguna nota"
              value={doctorFields.notes}
              onChange={handleFieldChange('notes')}
            />
          </Grid>
        </Grid>
        <Grid sx={styles.grid} container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              sx={styles.textField}
              type="number"
              size={sizeInput}
              fullWidth
              label="Comisión"
              placeholder="Ingrese la comisión"
              value={doctorFields.discount}
              onChange={handleFieldChange('discount')}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={styles.buttonGroup}>
        <Button
          sx={styles.button}
          variant="outlined"
          color="primary"
          onClick={handleCloseModal}
        >
          Cancelar
        </Button>
        <Button
          sx={styles.button}
          variant="contained"
          color="primary"
          disabled={disabledButton}
          onClick={handleSubmit}
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DoctorModal.propTypes = {
  open: PropTypes.bool,
  doctor: PropTypes.instanceOf(Object),
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
}

DoctorModal.defaultProps = {
  open: false,
  doctor: {},
  onClose: () => {},
  onSubmit: () => {},
}

export default DoctorModal
