import { TEST_COMPLETED } from '../constants/statusConstants'
import moment from 'moment'

export const calculateProgress = (tests = []) => {
  let testsCompleted = 0
  let percentage = 0

  if (tests.length) {
    tests.forEach(test => {
      if (test.Test_Consultation.status === TEST_COMPLETED) {
        testsCompleted += 1
      }
    })

    percentage = Math.round((testsCompleted * 100) / tests.length)
  }

  return {
    testsCompleted: `${testsCompleted}/${tests.length}`,
    percentage,
  }
}

export const getUrlForClientResults = consultationId => {
  const clientURL = process.env.REACT_APP_CLIENT_URL
  return `${clientURL}/resultados?id=${consultationId}`
}

export const getRefRange = ({
  custom_reference_range,
  reference_range,
  reference_range_2,
  reference_range_unit,
}) =>
  `${
    !!custom_reference_range
      ? custom_reference_range
      : `${reference_range} ${
          reference_range_2
            ? `- ${reference_range_2} ${reference_range_unit}`
            : reference_range_unit
        }`
  } `

export const getFileNameResults = consultationId =>
  `Resultados_C-${consultationId}_${moment().format('MM-DD-YYYY')}.pdf`
