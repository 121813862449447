import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useMediaQuery from '@mui/material/useMediaQuery'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import DateAdapter from '@mui/lab/AdapterMoment'
import moment from 'moment'
import { client } from '../../utils/client'
import { XLSX_CONSULTATION_RESULTS_PATH } from '../../constants/endpointsConstants'
import { getFileNameCompanyReport } from '../../utils/company'
import { useDispatch } from 'react-redux'
import { showSnackbar } from '../../actions/pageActions'
const styles = {
  textField: {
    marginBlock: { sm: '10px', xs: '0px' },
  },
  buttonGroup: {
    margin: '0 auto',
  },
  button: {
    margin: '10px',
  },
  grid: {
    marginBlock: { sm: '0px', xs: '10px' },
  },
}

const emptyParams = {
  startDate: moment().subtract(1, 'months'),
  endDate: moment(),
}

const CompanyReportModal = ({ open, company, onClose }) => {
  const initialParams = {
    startDate: moment().subtract(1, 'months'),
    endDate: moment(),
    companyId: company?.id,
  }
  const [params, setParams] = useState(initialParams)
  const [isLoading, setIsLoading] = useState(false)

  const matches = useMediaQuery('(max-width:575px)')
  const sizeInput = matches ? 'small' : 'normal'

  const dispatch = useDispatch()

  const handleCloseModal = () => {
    setParams(emptyParams)
    onClose()
  }

  const disabledButton = () => {
    const { startDate, endDate } = params
    return !(startDate?.isValid() && endDate?.isValid()) || isLoading
  }
  const handleSubmit = async () => {
    try {
      setIsLoading(true)
      const { data, headers } = await client.get(
        XLSX_CONSULTATION_RESULTS_PATH(company.id),
        {
          responseType: 'blob',
          params: {
            startDate: params.startDate.format('YYYY-MM-DD'),
            endDate: params.endDate.format('YYYY-MM-DD'),
          },
        }
      )
      const fileName =
        headers?.fileName || getFileNameCompanyReport(company.name)

      const file = new Blob([data])

      const url = URL.createObjectURL(file)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      setIsLoading(false)
      handleCloseModal()
    } catch (error) {
      setIsLoading(false)
      dispatch(showSnackbar())
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <DialogTitle sx={{ textAlign: 'center' }} variant="h6" component="h2">
        Reporte de "{company.name}"
      </DialogTitle>
      <DialogContent>
        <Grid sx={styles.grid} container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DatePicker
                disableFuture
                label="Desde"
                value={params.startDate}
                maxDate={params.endDate}
                onChange={date => {
                  setParams({
                    ...params,
                    startDate: date,
                  })
                }}
                renderInput={params => (
                  <TextField
                    sx={styles.textField}
                    fullWidth
                    required
                    size={sizeInput}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DatePicker
                label="Hasta"
                value={params.endDate}
                minDate={params.startDate}
                onChange={date => {
                  setParams({
                    ...params,
                    endDate: date,
                  })
                }}
                renderInput={params => (
                  <TextField
                    sx={styles.textField}
                    fullWidth
                    required
                    size={sizeInput}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={styles.buttonGroup}>
        <Button
          sx={styles.button}
          variant="outlined"
          color="primary"
          onClick={handleCloseModal}
        >
          Cancelar
        </Button>
        <Button
          sx={styles.button}
          variant="contained"
          color="primary"
          disabled={disabledButton()}
          onClick={handleSubmit}
        >
          Descargar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

CompanyReportModal.propTypes = {
  open: PropTypes.bool,
  company: PropTypes.instanceOf(Object),
  onClose: PropTypes.func,
}

CompanyReportModal.defaultProps = {
  open: false,
  company: {},
  onClose: () => {},
}

export default CompanyReportModal
