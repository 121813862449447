import React, { useEffect, useState } from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import moment from 'moment'
import DataGrid from '../DataGrid/DataGrid'
import { useDispatch, useSelector } from 'react-redux'
import PrintIcon from '@mui/icons-material/Print'
import PublicIcon from '@mui/icons-material/Public'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import EditButton from '@mui/icons-material/Edit'
import GradingIcon from '@mui/icons-material/Grading'
import { formatBirthday, getBirthday } from '../../utils/patient'
import { getDoctorFulName } from '../../utils/doctor'
import {
  getFileNameResults,
  getUrlForClientResults,
} from '../../utils/consultation'
import { showSnackbar } from '../../actions/pageActions'
import { client } from '../../utils/client'
import {
  CONSULTATION_PATH,
  PDF_CONSULTATION_RESULTS_PATH,
  PDF_LAB_ORDER_PATH,
  PDF_CREDENTIALS_PATH,
} from '../../constants/endpointsConstants'

import QRCode from 'qrcode.react'
import { TEST_COMPLETED } from '../../constants/statusConstants'
import { ADMIN, MANAGER } from '../../constants/rolesConstants'
import ModalPDF from '../ModalPDF/ModalPDF'
import RecordResultsModal from '../RecordResultsModal/RecordResultsModal'
import { DEFAULT_REQUESTER } from '../../constants/consultationsConstants'

const styles = {
  dialogTitle: {
    textAlign: 'center',
  },
  fieldset: {
    borderRadius: '8px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    minHeight: '95%',
  },
}

const ModalConsultationDetail = ({
  consultationId,
  open,
  onClose,
  onSaveResult,
}) => {
  const dispatch = useDispatch()

  const areas = useSelector(state => state.rootReducer.areas)
  const loginUserRole = useSelector(state => state.userReducer.user.role)

  const [consultation, setConsultation] = useState({})
  const [openResultsModal, setOpenResultsModal] = useState(false)
  const [resultsModalEdit, setResultsModalEdit] = useState(false)
  const [testSelected, setTestSelected] = useState(null)
  const [pdfUrl, setPdfUrl] = useState('')
  const [pdfName, setPdfName] = useState('')
  const [isLoading, setIsLoading] = useState({
    content: true,
    pdfLabOrder: false,
    pdfClientCredential: false,
    pdfConsultationResults: false,
  })

  const agePatient = getBirthday(consultation?.patient?.birthday)
  const total = consultation?.tests?.reduce((a, b) => a + b?.cost, 0)

  useEffect(() => {
    const getConsultationResults = async () => {
      setIsLoading({ ...isLoading, content: true })
      try {
        const { data } = await client.get(CONSULTATION_PATH(consultationId))
        setConsultation(data.data)
      } catch (error) {
        dispatch(showSnackbar())
      }
      setIsLoading({ ...isLoading, content: false })
    }

    getConsultationResults()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getResultsPDF = async () => {
    try {
      setIsLoading({ ...isLoading, pdfConsultationResults: true })
      const { data, headers } = await client.get(
        PDF_CONSULTATION_RESULTS_PATH(consultationId),
        {
          responseType: 'blob',
        }
      )
      const fileName = headers?.fileName || getFileNameResults(consultationId)

      setPdfName(fileName)
      const file = new Blob([data], { type: 'application/pdf' })
      const fileURL = URL.createObjectURL(file)
      setPdfUrl(fileURL)
      setIsLoading({ ...isLoading, pdfConsultationResults: false })
    } catch (error) {
      setIsLoading({ ...isLoading, pdfConsultationResults: false })
      dispatch(showSnackbar())
    }
  }

  const getLabOrderPDF = async () => {
    try {
      setIsLoading({ ...isLoading, pdfLabOrder: true })
      const { data, headers } = await client.get(
        PDF_LAB_ORDER_PATH(consultationId),
        {
          responseType: 'blob',
        }
      )
      const fileName = headers?.fileName

      setPdfName(fileName)
      const file = new Blob([data], { type: 'application/pdf' })
      const fileURL = URL.createObjectURL(file)
      setPdfUrl(fileURL)
      setIsLoading({ ...isLoading, pdfLabOrder: false })
    } catch (error) {
      setIsLoading({ ...isLoading, pdfLabOrder: false })
      dispatch(showSnackbar())
    }
  }

  const getCredentialsPDF = async () => {
    try {
      setIsLoading({ ...isLoading, pdfClientCredential: true })
      const { data, headers } = await client.get(
        PDF_CREDENTIALS_PATH(consultationId),
        {
          responseType: 'blob',
        }
      )
      const fileName = headers?.fileName

      setPdfName(fileName)
      const file = new Blob([data], { type: 'application/pdf' })
      const fileURL = URL.createObjectURL(file)
      setPdfUrl(fileURL)
      setIsLoading({ ...isLoading, pdfClientCredential: false })
    } catch (error) {
      setIsLoading({ ...isLoading, pdfClientCredential: false })
      dispatch(showSnackbar())
    }
  }

  const handleTestSelect = (test, isEdit = false) => {
    setResultsModalEdit(isEdit)
    setTestSelected(test)
    setOpenResultsModal(true)
  }

  const handleCloseResultsModal = () => {
    setOpenResultsModal(false)
    setTestSelected(null)
  }

  const handleSaveResults = testToUpdate => {
    const tests = consultation?.tests.map(test =>
      test.id === testToUpdate.testId
        ? {
            ...test,
            status: testToUpdate.status,
            observation: testToUpdate.observation,
          }
        : test
    )
    setConsultation({
      ...consultation,
      tests,
    })
    onSaveResult()
  }

  const columnsTest = [
    {
      background: '#e6fced',
      field: 'results',
      headerName: 'Resultados',
      flex: 0.3,
      minWidth: 100,
      sortable: false,
      align: 'center',
      renderCell: params => (
        <>
          {params.row.status === TEST_COMPLETED ? (
            <>
              <CheckCircleOutlineIcon color="success" />
              {[ADMIN, MANAGER].includes(loginUserRole?.name) && (
                <IconButton
                  color="primary"
                  onClick={() => handleTestSelect(params.row, true)}
                >
                  <EditButton />
                </IconButton>
              )}
            </>
          ) : (
            <IconButton
              color="primary"
              onClick={() => handleTestSelect(params.row)}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          )}
        </>
      ),
    },
    {
      field: 'name',
      headerName: 'Nombre',
      flex: 0.6,
      minWidth: 130,
    },
    {
      field: 'area',
      headerName: 'Área',
      flex: 0.6,
      minWidth: 130,
      valueGetter: params =>
        areas.find(area => area.id === params.row.areaId)?.name,
    },
    {
      field: 'cost',
      headerName: 'Costo',
      flex: 0.6,
      minWidth: 80,
      valueGetter: params => `${params.row?.cost} Bs.`,
    },
    {
      field: 'comment',
      headerName: 'Comentario',
      flex: 0.6,
      minWidth: 150,
    },
  ]

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <DialogTitle sx={styles.dialogTitle} variant="h5" component="h2">
        Detalle de Consulta
      </DialogTitle>
      <DialogContent>
        {!isLoading.content ? (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <fieldset style={styles.fieldset}>
                <legend>
                  <Typography variant="body1">Consulta</Typography>
                </legend>
                <Typography variant="body1">
                  <b>Código:</b> {`C-${consultation?.id}`}
                </Typography>
                <Typography variant="body1">
                  <b>Fecha:</b> {moment(consultation?.date).format('L')}
                </Typography>
                <Typography variant="body1">
                  <b>Sucursal Origen:</b> {consultation?.subsidiary?.name}
                </Typography>
                <Typography variant="body1">
                  <b>Atendido por:</b>
                  {` ${consultation?.user?.name || ''} 
                  ${consultation?.user?.lastNameFather || ''}`}
                </Typography>
                <Typography variant="body1">
                  <b>Número de análisis: </b>
                  {consultation?.tests?.length}
                </Typography>
                <Typography variant="body1">
                  <b>Costo Total:</b>
                  {` ${total} Bs.`}
                </Typography>
                <Typography variant="body1">
                  <b>Médico/Solicitante: </b>
                  {consultation?.doctorId
                    ? getDoctorFulName(consultation?.doctor)
                    : consultation?.requester || DEFAULT_REQUESTER}
                </Typography>
              </fieldset>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <fieldset style={styles.fieldset}>
                <legend>
                  <Typography variant="body1">Paciente</Typography>
                </legend>
                <Typography variant="body1">
                  <b>Nombre(s):</b> {consultation?.patient?.name}
                </Typography>
                <Typography variant="body1">
                  <b>Apellido(s):</b>
                  {` ${consultation?.patient?.lastNameFather || ''} 
                  ${consultation?.patient?.lastNameMother || ''}`}
                </Typography>
                <Typography variant="body1">
                  <b>CI:</b> {consultation?.patient?.identityCard}
                </Typography>
                <Typography variant="body1">
                  <b>Teléfono:</b> {consultation?.patient?.landline}
                </Typography>
                <Typography variant="body1">
                  <b>Celular:</b> {consultation?.patient?.cellPhone}
                </Typography>
                <Typography variant="body1">
                  <b>Fecha de Nacimiento:</b>
                  {formatBirthday(consultation?.patient?.birthday, agePatient)}
                </Typography>
                <Typography variant="body1">
                  <b>Correo:</b> {consultation?.patient?.email}
                </Typography>
                <Typography variant="body1">
                  <b>Género:</b>
                  {consultation?.patient?.gender === 'M'
                    ? ' Masculino'
                    : ' Femenino'}
                </Typography>
                <div style={{ display: 'none' }}>
                  <QRCode
                    id={`qr-receipt-${consultation?.id}`}
                    value={getUrlForClientResults(
                      consultation?.id,
                      consultation?.password
                    )}
                  />
                </div>
              </fieldset>
            </Grid>
            <Grid item xs={12}>
              <DialogTitle sx={styles.dialogTitle} variant="h5" component="h2">
                Lista de Análisis
              </DialogTitle>
              <DataGrid columns={columnsTest} rows={consultation?.tests} />
            </Grid>
          </Grid>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </div>
        )}
      </DialogContent>
      <DialogActions sx={{ margin: '0 auto' }}>
        <Grid container justifyContent="center">
          <Button
            variant="outlined"
            color="primary"
            onClick={onClose}
            sx={{ margin: '5px', width: { xs: '150px' } }}
          >
            Cerrar
          </Button>

          <Button
            variant="contained"
            color="primary"
            endIcon={<PrintIcon />}
            sx={{ margin: '5px', width: { xs: '150px' } }}
            onClick={() => getLabOrderPDF()}
            disabled={isLoading.pdfLabOrder || isLoading.content}
          >
            Orden
          </Button>

          <Button
            variant="contained"
            color="primary"
            endIcon={<PublicIcon />}
            fullWidth
            sx={{ margin: '5px', width: { xs: '150px' } }}
            onClick={() => getCredentialsPDF()}
            disabled={isLoading.pdfClientCredential || isLoading.content}
          >
            Credenciales
          </Button>

          <Button
            variant="contained"
            color="success"
            onClick={() => getResultsPDF()}
            disabled={isLoading.pdfConsultationResults || isLoading.content}
            endIcon={<GradingIcon />}
            sx={{ margin: '5px', width: '150px' }}
          >
            Resultados
          </Button>
        </Grid>
      </DialogActions>
      {!!pdfUrl && (
        <ModalPDF
          onClose={() => setPdfUrl('')}
          pdfUrl={pdfUrl}
          title={`C-${consultation?.id}`}
          fileName={pdfName}
        />
      )}
      {openResultsModal && (
        <RecordResultsModal
          test={testSelected}
          isEdit={resultsModalEdit}
          onClose={handleCloseResultsModal}
          onSave={handleSaveResults}
        />
      )}
    </Dialog>
  )
}

export default ModalConsultationDetail
