import React from 'react'
import PropTypes from 'prop-types'
import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import DownloadIcon from '@mui/icons-material/Download'
import { saveAs } from 'file-saver'

const ModalPDF = ({ onClose, pdfUrl, title, fileName }) => {
  const onDownload = () => {
    saveAs(pdfUrl, fileName)
  }

  return (
    <Dialog
      fullScreen
      open={true}
      onClose={onClose}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <AppBar
        sx={{
          position: 'relative',
          '& .MuiToolbar-root ': {
            minHeight: '50px',
          },
        }}
      >
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {title}
          </Typography>

          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <object data={pdfUrl} type="application/pdf" height="100%" width="100%">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Button
            variant="contained"
            startIcon={<DownloadIcon />}
            onClick={onDownload}
          >
            Descargar
          </Button>
        </div>
      </object>
    </Dialog>
  )
}

ModalPDF.propTypes = {
  title: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  pdfUrl: PropTypes.string.isRequired,
  onClose: PropTypes.func,
}

export default ModalPDF
