import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Alert,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material'
import { isEmpty } from 'lodash'

import Step1 from './Step1/Step1'
import Step2 from './Step2/Step2'
import Step3 from './Step3/Step3'
import Step3Optional from './Step3Optional/Step3Optional'

const styles = {
  buttonGroup: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    margin: '10px',
  },
  stack: {
    width: '100%',
    padding: '15px',
  },
}
const DEFAULT_STEPS = ['Análisis', 'Paciente', 'Resumen']
const STEPS_WITH_COMPANY = [
  'Análisis',
  'Paciente',
  'Titular Empresa',
  'Resumen',
]

const ModalConsultation = ({ open, consultation, onClose, onSubmit }) => {
  const subsidiaryId = useSelector(
    state => state.userReducer.user.subsidiary?.id
  )
  const isLoading = useSelector(state => state.pageReducer.isLoading)

  const initialConsultation = !isEmpty(consultation)
    ? consultation
    : { patientId: null, tests: [], subsidiaryId }

  const initialPatient = consultation?.patient ? [consultation?.patient] : []
  const initialAssureds = consultation?.assured ? [consultation?.assured] : []

  const [steps, setSteps] = useState(
    consultation.companyId ? STEPS_WITH_COMPANY : DEFAULT_STEPS
  )
  const [activeStep, setActiveStep] = useState(0)
  const [patients, setPatients] = useState(initialPatient)
  const [assureds, setAssureds] = useState(initialAssureds)
  const [consultationToSend, setConsultationToSend] =
    useState(initialConsultation)

  const handleCloseModal = () => {
    onClose()
  }

  const handleSubmit = () => {
    if (onSubmit(consultationToSend)) {
      onClose()
    }
  }

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    if (consultationToSend.patientId) {
      setPatients([consultationToSend.patient])
    } else {
      setPatients([])
    }
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleChangeStep1 = tests => {
    setConsultationToSend({
      ...consultationToSend,
      tests: Object.values(tests),
    })
  }

  const handleChangeStep2 = patient => {
    if (patient) {
      setConsultationToSend({
        ...consultationToSend,
        patientId: patient.id,
        patient,
      })
      setAssureds([patient])
    } else {
      setConsultationToSend({
        ...consultationToSend,
        patientId: null,
        patient: null,
      })
      setAssureds([])
    }
  }

  const handleChangeStep3Optional = assured => {
    if (assured) {
      setConsultationToSend({
        ...consultationToSend,
        assuredId: assured.id,
        assured: assured,
      })
      setAssureds([assured])
    } else {
      setConsultationToSend({
        ...consultationToSend,
        assuredId: null,
        assured: null,
      })
      setAssureds([])
    }
  }

  const handleChangeCompany = company => {
    if (company?.id) {
      setSteps(STEPS_WITH_COMPANY)
      setConsultationToSend({
        ...consultationToSend,
        companyId: company.id,
        company,
      })
    } else {
      setSteps(DEFAULT_STEPS)
      setConsultationToSend({
        ...consultationToSend,
        companyId: null,
        company: null,
        assuredId: null,
        assured: null,
      })
    }
  }

  const handleChangeStep3 = newConsultationFields => {
    setConsultationToSend({
      ...consultationToSend,
      ...newConsultationFields,
    })
  }

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <Step1
            tests={consultationToSend.tests}
            onChange={handleChangeStep1}
          />
        )
      case 1:
        return (
          <Step2
            patients={patients}
            setPatients={setPatients}
            onChangeStep2={handleChangeStep2}
            onChangeCompany={handleChangeCompany}
            company={consultationToSend.company}
          />
        )
      case 2:
        if (consultationToSend.companyId)
          return (
            <Step3Optional
              assureds={assureds}
              setAssureds={setAssureds}
              onChangeStep3Optional={handleChangeStep3Optional}
              patient={consultationToSend.patient}
              company={consultationToSend.company}
            />
          )
        return (
          <Step3
            patient={consultationToSend.patient}
            tests={consultationToSend.tests}
            totalPrice={totalPrice()}
            onChangeStep3={handleChangeStep3}
            subsidiaryId={consultationToSend.subsidiaryId}
            requester={consultationToSend.requester}
            company={consultationToSend.company}
            assured={consultationToSend.assured}
            doctor={consultationToSend.doctor}
          />
        )
      case 3:
        return (
          <Step3
            patient={consultationToSend.patient}
            tests={consultationToSend.tests}
            totalPrice={totalPrice()}
            onChangeStep3={handleChangeStep3}
            subsidiaryId={consultationToSend.subsidiaryId}
            requester={consultationToSend.requester}
            company={consultationToSend.company}
            assured={consultationToSend.assured}
            doctor={consultationToSend.doctor}
          />
        )
      default:
        return 'Empty step'
    }
  }

  const disableNextButton = () => {
    switch (activeStep) {
      case 0:
        return !consultationToSend.tests.length
      case 1:
        return !consultationToSend.patientId
      case 2:
        if (consultationToSend.companyId)
          return (
            !consultationToSend.patientId ||
            !consultationToSend.assuredId ||
            !consultationToSend.companyId
          )
        return (
          !consultationToSend.patientId ||
          !consultationToSend.subsidiaryId ||
          !consultationToSend.tests.length
        )
      case 3:
        return (
          !consultationToSend.patientId ||
          !consultationToSend.subsidiaryId ||
          !consultationToSend.tests.length ||
          isLoading
        )
      default:
        return false
    }
  }

  const totalPrice = () =>
    consultationToSend.tests.reduce((a, b) => a + b.cost, 0)

  const ButtonActions = () => {
    const isFirstStep = activeStep === 0
    const isLastStep = activeStep === steps.length - 1

    return (
      <>
        <Button
          sx={styles.button}
          variant="outlined"
          color="primary"
          onClick={isFirstStep ? handleCloseModal : handleBack}
        >
          {isFirstStep ? 'Cancelar' : 'Atras'}
        </Button>

        <Button
          sx={styles.button}
          variant="contained"
          color="primary"
          onClick={isLastStep ? handleSubmit : handleNext}
          disabled={disableNextButton()}
        >
          {isLastStep ? (consultation.id ? 'Editar' : 'Crear') : 'Siguiente'}
        </Button>
      </>
    )
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <DialogTitle>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </DialogTitle>
      <DialogContent>{getStepContent(activeStep)}</DialogContent>
      <DialogActions>
        <Grid container>
          {consultation.id && (
            <Stack sx={styles.stack} spacing={2}>
              <Alert severity="warning">
                Al editar la consulta los resultados registrados serán
                eliminados
              </Alert>
            </Stack>
          )}
          {activeStep === 0 && (
            <Grid item xs={12}>
              <Container>
                <Typography align="right">
                  <b>Nº de Ánalisis: </b>
                  {consultationToSend?.tests?.length}
                  <b> Total: </b>
                  {totalPrice() + ' Bs.'}
                </Typography>
              </Container>
            </Grid>
          )}
          <Grid item xs={12} sx={styles.buttonGroup}>
            {ButtonActions()}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

ModalConsultation.propTypes = {
  open: PropTypes.bool.isRequired,
  consultation: PropTypes.instanceOf(Object),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

ModalConsultation.defaultProps = {
  consultation: {
    tests: [],
  },
}

export default ModalConsultation
