import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material'
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DoneIcon from '@mui/icons-material/Done'
import PersonIcon from '@mui/icons-material/Person'
import VpnKeyIcon from '@mui/icons-material/VpnKey'

const styles = {
  box: {
    marginTop: '20px',
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
  },
  littleBox: {
    marginBlock: '10px',
  },
  copy: {
    margin: '0 auto',
  },
}
const DisplayCredentials = ({ open, password, userName, onClose }) => {
  const [clicked, setClicked] = useState(false)

  const handleClickCopy = () => {
    setClicked(true)
    navigator.clipboard.writeText(
      `usuario: ${userName} contraseña: ${password}`
    )
  }

  const handleClose = () => {
    onClose()
    setClicked(false)
  }
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={handleClose}>
      <DialogTitle>Nombre de usuario y contraseña</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Por favor, copie sus credenciales acontinuacion:
        </DialogContentText>
        <Box sx={styles.box}>
          <Box sx={styles.littleBox}>
            <Chip
              color={clicked ? 'success' : 'info'}
              label={userName}
              deleteIcon={clicked ? <DoneIcon /> : <EmojiEmotionsIcon />}
              onDelete={() => {}}
              icon={<PersonIcon />}
            />
          </Box>
          <Box sx={styles.littleBox}>
            <Chip
              color={clicked ? 'success' : 'info'}
              label={password}
              deleteIcon={clicked ? <DoneIcon /> : <EmojiEmotionsIcon />}
              onDelete={() => {}}
              icon={<VpnKeyIcon />}
            />
          </Box>
          <Box sx={styles.copy}>
            <Tooltip title="Copiar" onClick={() => handleClickCopy()}>
              <IconButton>
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Aceptar</Button>
      </DialogActions>
    </Dialog>
  )
}

DisplayCredentials.propTypes = {
  open: PropTypes.bool.isRequired,
  password: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default DisplayCredentials
