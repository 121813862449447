import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { INPUTS_TYPE } from '../../../constants/inputsConstants'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import ModalTypeResult from '../../ModalTypeResult/ModalTypeResult'
import ViewModuleIcon from '@mui/icons-material/ViewModule'
import { setLoading, showSnackbar } from '../../../actions/pageActions'
import { useDispatch } from 'react-redux'
import { client } from '../../../utils/client'
import { TYPE_RESULT_PATH } from '../../../constants/endpointsConstants'
import InputResult from '../InputResult/InputResult'
import SortTypeResultsModal from '../../SortTypeResultsModal/SortTypeResultsModal'

const styles = {
  buttonGroup: {
    margin: '0 auto',
  },
  button: {
    margin: '10px',
  },
  buttonActions: {
    display: 'flex',
    justifyContent: 'space-between',
  }
}

const TypeResultList = ({
  open,
  typeResults,
  test,
  handleChagesOnTypeResults,
  onClose,
  onChangeTypeResults,
}) => {
  const dispatch = useDispatch()

  const [openModal, setOpenModal] = useState(false)
  const [selectedTypeResult, setSelectedTypeResult] = useState({})
  const [openSortModal, setOpenSortModal] = useState(false)

  const handleCloseModal = () => onClose()

  const handleOpen = () => setOpenModal(true)

  const handleClose = () => {
    setOpenModal(false)
    setSelectedTypeResult({})
  }

  const handleSelectedTypeResult = typeResult => {
    setSelectedTypeResult(typeResult)
    handleOpen()
  }

  const handleLoading = value => {
    dispatch(setLoading(value))
  }

  const handleCreateTypeResult = async typeResult => {
    let success = false
    handleLoading(true)
    try {
      const {
        data: { message, data: newTypeResult },
      } = await client.post(TYPE_RESULT_PATH, typeResult)
      dispatch(showSnackbar(message, 'success'))
      handleClose()
      success = true
      handleChagesOnTypeResults(newTypeResult, 'insert')
    } catch (error) {
      dispatch(showSnackbar(error?.response?.data?.message))
    }
    handleLoading(false)
    return success
  }

  const handleEditTypeResult = async typeResult => {
    let success = false
    handleLoading(true)
    try {
      const {
        data: { message, data: updatedTypeResult },
      } = await client.put(
        `${TYPE_RESULT_PATH}/${selectedTypeResult?.id}`,
        typeResult
      )
      dispatch(showSnackbar(message, 'success'))
      handleChagesOnTypeResults(updatedTypeResult, 'update')
      handleClose()
      success = true
    } catch (error) {
      dispatch(showSnackbar(error?.response?.data?.message))
    }
    handleLoading(false)
    return success
  }

  const handleCloseSortOrderModal = (typeResults = []) => {
    setOpenSortModal(false)
    if (typeResults.length) {
      onChangeTypeResults(typeResults)
    }
  }

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <DialogTitle sx={{ textAlign: 'center' }} variant="h6" component="h2">
          Tipos de resultados
        </DialogTitle>
        <DialogContent>
          <Box sx={{ textAlign: 'end', marginBottom: '8px' }}>
            <Button
              sx={{ marginRight: '10px' }}
              disabled={!typeResults.length}
              startIcon={<ViewModuleIcon />}
              variant="outlined"
              onClick={() => setOpenSortModal(true)}
            >
              Cambiar Orden
            </Button>
            <Button
              sx={{ width: '130px' }}
              variant="contained"
              startIcon={<AddCircleIcon />}
              onClick={handleOpen}
            >
              Agregar
            </Button>
          </Box>
          {typeResults?.length ? (
            <Grid container spacing={{ xs: 2, md: 3 }} justifyContent="center">
              {typeResults?.map(typeResult => (
                <Grid key={typeResult.id} item xs={12} sm={6} md={4}>
                  <Card variant="outlined">
                    <CardContent>
                      <InputResult typeResult={typeResult} />
                      <Typography color="text.secondary">
                        <b>Tipo de entrada: </b>
                        {INPUTS_TYPE[typeResult?.type_of_input].label}
                      </Typography>
                    </CardContent>
                    <CardActions sx={styles.buttonActions}>
                      <Button
                        size="small"
                        onClick={() => handleSelectedTypeResult(typeResult)}
                      >
                        Editar
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Alert severity="warning">
              No existe tipos de resultados para este análisis.
            </Alert>
          )}
        </DialogContent>
        <DialogActions sx={styles.buttonGroup}>
          <Button
            sx={styles.button}
            variant="outlined"
            color="primary"
            onClick={handleCloseModal}
          >
            Cancelar
          </Button>
        </DialogActions>

        {openModal && (
          <ModalTypeResult
            open={openModal}
            typeResult={selectedTypeResult}
            onClose={handleClose}
            test={test}
            onSubmit={
              selectedTypeResult?.id
                ? handleEditTypeResult
                : handleCreateTypeResult
            }
          />
        )}

        {openSortModal && (
          <SortTypeResultsModal
            open={openSortModal}
            typeResults={typeResults}
            testId={test?.id}
            onClose={handleCloseSortOrderModal}
          />
        )}
      </Dialog>
    </div>
  )
}

export default TypeResultList
