import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop'
import moment from 'moment'

import ModalPatient from '../ModalPatient/ModalPatient'
import { showSnackbar } from '../../actions/pageActions'

import { client } from '../../utils/client'
import {
  PATIENTS_PATH,
  SEARCH_PATIENT_PATH,
} from '../../constants/endpointsConstants'

const styles = {
  gridButton: {
    display: 'flex',
    justifyContent: 'center',
  },
}

const NoResultContent = ({ openModal, setOpenModal, onCreatePatient }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      flexDirection: 'column',
    }}
  >
    <div style={{ textAlign: 'center' }}>
      <ScreenSearchDesktopIcon sx={{ fontSize: 80, display: 'block' }} />
      Lista Vacia
    </div>

    <Button
      sx={{ marginBlock: '10px' }}
      variant="contained"
      startIcon={<PersonAddIcon />}
      onClick={() => setOpenModal(true)}
    >
      Agregar
    </Button>

    <ModalPatient
      open={openModal}
      onClose={() => setOpenModal(false)}
      onSubmit={onCreatePatient}
    />
  </div>
)

const SelectPatient = ({ patients, setPatients, onChangeSelection }) => {
  const dispatch = useDispatch()

  const [pageSize, setPageSize] = useState(10)
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [searchValues, setSearchValues] = useState({
    fullName: '',
    ci: '',
    limit: 10,
  })

  const columns = [
    { field: 'name', headerName: 'Nombre', flex: 0.6, minWidth: 120 },
    {
      field: 'lastNameFather',
      headerName: 'A. Paterno',
      flex: 0.6,
      minWidth: 110,
    },
    {
      field: 'lastNameMother',
      headerName: 'A. Materno',
      flex: 0.6,
      minWidth: 110,
    },
    {
      field: 'identityCard',
      headerName: 'CI',
      flex: 0.6,
      minWidth: 100,
    },
    {
      field: 'gender',
      headerName: 'Género',
      flex: 0.6,
      minWidth: 100,
    },
    { field: 'id', headerName: 'ID', minWidth: 50, hide: true },
    { field: 'cellPhone', headerName: 'Celular', minWidth: 50, hide: true },
    { field: 'email', headerName: 'Email', minWidth: 150, hide: true },
    {
      field: 'birthday',
      headerName: 'Fecha Nacimiento',
      minWidth: 50,
      hide: true,
    },
    {
      field: 'landline',
      headerName: 'Teléfono fijo',
      minWidth: 50,
      hide: true,
    },
  ]

  const handleFieldChange = prop => event => {
    const {
      target: { value },
    } = event
    if (prop === 'ci' && value !== '') {
      const number = Number(value)
      if (number >= 0) {
        setSearchValues({ ...searchValues, [prop]: number })
      }
    } else {
      setSearchValues({ ...searchValues, [prop]: value })
    }
  }

  const handleSearch = async () => {
    onChangeSelection(null)
    setLoading(true)
    try {
      const limit = searchValues.limit
      if (searchValues.fullName !== '') {
        const fullName = searchValues.fullName.trim()
        const {
          data: { data: searchResult },
        } = await client.get(
          `${PATIENTS_PATH}/${SEARCH_PATIENT_PATH}?searchText=${fullName}&orderBy=fullName&limit=${limit}`
        )
        setPatients(searchResult)
      } else {
        const {
          data: { data: searchResult },
        } = await client.get(
          `${PATIENTS_PATH}/${SEARCH_PATIENT_PATH}?searchText=${searchValues.ci}&orderBy=ci&limit=${limit}`
        )
        setPatients(searchResult)
      }
    } catch (error) {
      dispatch(showSnackbar(error?.response?.data?.message))
    }
    setLoading(false)
  }

  const disabledButton = () =>
    searchValues.fullName === '' && searchValues.ci === ''

  const handlePressEnter = event => {
    if (event.key === 'Enter' && !disabledButton()) {
      handleSearch()
    }
  }

  const handleCreatePatient = async newPatient => {
    let success = false
    newPatient.birthday = moment(newPatient.birthday).format('YYYY-MM-DD')
    try {
      const {
        data: { message, data: patient },
      } = await client.post(`${PATIENTS_PATH}`, newPatient)

      setPatients([patient, ...patients])
      dispatch(showSnackbar(message, 'success'))
      setOpenModal(false)
      success = true
    } catch (error) {
      dispatch(showSnackbar(error?.response?.data?.message))
    }

    return success
  }

  const handleSelectPatient = patient => {
    onChangeSelection(patient?.row)
  }
  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems="center"
        sx={{ marginBottom: '30px' }}
      >
        <Grid item xs={12} sm={3} md={3}>
          <TextField
            sx={styles.textField}
            disabled={searchValues.fullName !== ''}
            fullWidth
            type="number"
            label="CI"
            placeholder="Ingrese el CI"
            value={searchValues.ci}
            onChange={handleFieldChange('ci')}
            onKeyDown={handlePressEnter}
          />
        </Grid>
        <Grid item xs={12} sm={5} md={5}>
          <TextField
            sx={styles.textField}
            disabled={searchValues.ci !== ''}
            fullWidth
            type="search"
            label="Nombre Completo"
            placeholder="Ingrese el nombre completo"
            value={searchValues.fullName}
            onChange={handleFieldChange('fullName')}
            onKeyDown={handlePressEnter}
          />
        </Grid>
        <Grid item xs={12} sm={2} md={2}>
          <FormControl fullWidth>
            <InputLabel>Limite</InputLabel>
            <Select
              value={searchValues.limit}
              label="Limite"
              onChange={handleFieldChange('limit')}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={30}>30</MenuItem>
              <MenuItem value={40}>40</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={5} md={2} sx={styles.gridButton}>
          <Button
            sx={styles.button}
            variant="contained"
            color="primary"
            disabled={disabledButton()}
            onClick={handleSearch}
          >
            Buscar
          </Button>
        </Grid>
      </Grid>
      <div className="data-grid" style={{ height: '400px' }}>
        <DataGrid
          sx={{ width: '100%' }}
          rows={patients}
          columns={columns}
          components={{
            NoRowsOverlay: () => (
              <NoResultContent
                openModal={openModal}
                setOpenModal={setOpenModal}
                onCreatePatient={handleCreatePatient}
              />
            ),
          }}
          onPageSizeChange={newPageSize => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 25, 50]}
          pageSize={pageSize}
          disableColumnReorder={true}
          loading={loading}
          onRowClick={handleSelectPatient}
        />
      </div>
    </>
  )
}

export default SelectPatient
