import React from 'react'
import PropTypes from 'prop-types'
import { Drawer } from '@mui/material'
import ItemsSideBar from '../ItemsSideBar/ItemsSideBar'

const MobileSideBar = ({ mobileOpen, drawerWidth, onHandleDrawerToggle }) => {
  return (
    <Drawer
      variant="temporary"
      open={mobileOpen}
      onClose={onHandleDrawerToggle}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        display: { xs: 'block', sm: 'none' },
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: drawerWidth,
        },
      }}
    >
      <ItemsSideBar />
    </Drawer>
  )
}

MobileSideBar.propTypes = {
  mobileOpen: PropTypes.bool.isRequired,
  drawerWidth: PropTypes.number.isRequired,
  onHandleDrawerToggle: PropTypes.func.isRequired,
}

export default MobileSideBar
