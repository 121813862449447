import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { client } from '../../utils/client'
import { isEmpty } from 'lodash'

import BiotechIcon from '@mui/icons-material/Biotech'
import EditButton from '@mui/icons-material/Edit'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import { Button, Container, IconButton, Typography, Box } from '@mui/material'
import { SearchBar } from '../../components/SearchBar/SearchBar'

import CustomPaginationDataGrid from '../../components/CustomPaginationDataGrid/CustomPaginationDataGrid'
import DeletePatient from '../../components/Patient/DeletePatient'
import ModalPatient from '../../components/ModalPatient/ModalPatient'

import { setLoading, showSnackbar } from '../../actions/pageActions'

import { PATIENTS_PATH } from '../../constants/endpointsConstants'
import { ADMIN } from '../../constants/rolesConstants'

import moment from 'moment'
import 'moment/locale/es'

const PATIENT_SEARCH_CRITERIA = [
  { label: 'CI', type: 'number', prop: 'identityCard' },
  { label: 'Nombre(s)', type: 'text', prop: 'name' },
  { label: 'Apellido(s)', type: 'text', prop: 'lastName' },
]
const PatientsList = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const loginUser = useSelector(state => state.userReducer.user)

  const [loaderTable, setLoaderTable] = useState(true)
  const [openModal, setOpenModal] = useState(false)
  const [patients, setPatients] = useState([])
  const [selectedPatient, setSelectedPatient] = useState({})
  const [page, setPage] = useState(0)
  const [totalPatients, setTotalPatients] = useState(0)
  const [limit, setLimit] = useState(10)
  const [searchFields, setSearchFields] = useState({})

  const removePatient = patientId => {
    const newList = patients.filter(patient => patient.id !== patientId)
    setPatients(newList)
    setTotalPatients(totalPatients - 1)
  }

  const columns = [
    { field: 'name', headerName: 'Nombre', flex: 0.6, minWidth: 120 },
    {
      field: 'lastNameFather',
      headerName: 'A. Paterno',
      flex: 0.6,
      minWidth: 110,
    },
    {
      field: 'lastNameMother',
      headerName: 'A. Materno',
      flex: 0.6,
      minWidth: 110,
    },
    {
      field: 'identityCard',
      headerName: 'CI',
      flex: 0.6,
      minWidth: 100,
    },
    {
      field: 'gender',
      headerName: 'Género',
      flex: 0.6,
      minWidth: 80,
    },
    {
      field: 'landline',
      headerName: 'Teléfono',
      flex: 0.6,
      minWidth: 100,
    },

    {
      field: 'cellPhone',
      headerName: 'Celular',
      flex: 0.6,
      minWidth: 100,
    },
    {
      field: 'birthday',
      headerName: 'Fecha de Nacimiento',
      flex: 0.7,
      minWidth: 100,
      valueGetter: params => moment(params.row.birthday).format('L'),
    },
    {
      field: 'email',
      headerName: 'Correo',
      flex: 0.6,
      minWidth: 160,
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      flex: 0.6,
      minWidth: 130,
      sortable: false,
      renderCell: params => (
        <>
          <IconButton
            color="primary"
            onClick={() => handleSelectedPatient(params.row)}
          >
            <EditButton />
          </IconButton>
          {loginUser?.role?.name === ADMIN && (
            <DeletePatient patient={params.row} onDelete={removePatient} />
          )}
          <IconButton
            color="primary"
            onClick={() =>
              history.push(`patients/${params.row.id}/consultations`)
            }
          >
            <BiotechIcon />
          </IconButton>
        </>
      ),
    },
  ]

  const handleLoading = value => {
    setLoaderTable(value)
    dispatch(setLoading(value))
  }

  const handleSelectedPatient = patient => {
    setSelectedPatient(patient)
    handleOpen()
  }

  const handleOpen = () => {
    setOpenModal(true)
  }

  const handleClose = () => {
    setOpenModal(false)
    setSelectedPatient({})
  }

  const handleCreatePatient = async newPatient => {
    dispatch(setLoading(true))
    let success = false
    newPatient.birthday = moment(newPatient.birthday).format('YYYY-MM-DD')
    try {
      const {
        data: { message, data: patient },
      } = await client.post(`${PATIENTS_PATH}`, newPatient)

      dispatch(showSnackbar(message, 'success'))
      setPatients([patient, ...patients])
      setTotalPatients(totalPatients + 1)
      handleClose()
      success = true
    } catch (error) {
      dispatch(showSnackbar(error?.response?.data?.message))
    }

    dispatch(setLoading(false))
    return success
  }

  const handleEditPatient = async patient => {
    dispatch(setLoading(true))
    let success = false
    patient.birthday = moment(patient.birthday).format('YYYY-MM-DD')

    try {
      const {
        data: { message, data: updatedPatient },
      } = await client.put(`${PATIENTS_PATH}/${selectedPatient?.id}`, patient)

      dispatch(showSnackbar(message, 'success'))
      const listUpdate = patients.map(item =>
        item.id === updatedPatient.id ? updatedPatient : item
      )
      setPatients(listUpdate)
      handleClose()
      success = true
    } catch (error) {
      dispatch(showSnackbar(error?.response?.data?.message))
    }

    dispatch(setLoading(false))
    return success
  }

  const handleSearch = values => setSearchFields(values)

  useEffect(() => {
    handleLoading(true)
    client
      .get(PATIENTS_PATH, {
        params: {
          limit,
          page: page + 1,
          ...searchFields,
        },
      })
      .then(response => {
        setPatients(response.data.data)
        setTotalPatients(response.data.total)
        handleLoading(false)
      })
      .catch(() => {
        handleLoading(false)
        dispatch(showSnackbar())
      })
    // eslint-disable-next-line
  }, [limit, page, searchFields])

  return (
    <Container maxWidth="xl">
      <Typography variant="h3" sx={{ marginBlock: '25px' }}>
        Lista de Pacientes
      </Typography>
      <Box sx={{ textAlign: 'end', marginBottom: '8px' }}>
        <Button
          sx={{ width: '130px' }}
          variant="contained"
          startIcon={<PersonAddIcon />}
          onClick={handleOpen}
        >
          Agregar
        </Button>
      </Box>
      <SearchBar onSubmit={handleSearch} items={PATIENT_SEARCH_CRITERIA} />
      <CustomPaginationDataGrid
        columns={columns}
        items={patients}
        limit={limit}
        loading={loaderTable}
        page={page}
        totalItems={totalPatients}
        setPage={setPage}
        setLimit={setLimit}
      />

      {openModal && (
        <ModalPatient
          open={openModal}
          patient={selectedPatient}
          onClose={handleClose}
          onSubmit={
            isEmpty(selectedPatient) ? handleCreatePatient : handleEditPatient
          }
        />
      )}
    </Container>
  )
}

export default PatientsList
