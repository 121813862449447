import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'

import ScrollToHereOnMount from '../../../common/ScrollToHereOnMount/ScrollToHereOnMount'

import SelectCompany from '../../SelectCompany/SelectCompany'
import SelectPatient from '../../SelectPatient/SelectPatient'

const styles = {
  companySelector: {
    marginTop: '20px',
  },
}

const Step2 = ({
  patients,
  setPatients,
  onChangeStep2,
  onChangeCompany,
  company,
}) => {
  const matches = useMediaQuery('(max-width:575px)')

  const handleSelectCompany = company => {
    onChangeCompany(company)
  }

  return (
    <div
      style={{ padding: `${matches ? '20px 0' : '20px'}`, overflowY: 'clip' }}
    >
      <ScrollToHereOnMount />
      <SelectPatient
        patients={patients}
        setPatients={setPatients}
        onChangeSelection={onChangeStep2}
      />

      <Grid container alignItems="initial" sx={styles.companySelector}>
        <Grid item xs={12} sm={6} md={4}>
          <SelectCompany
            onCompanySelected={handleSelectCompany}
            defaultCompany={company}
          />
        </Grid>
      </Grid>
    </div>
  )
}

Step2.propTypes = {
  patients: PropTypes.instanceOf(Array),
  setPatients: PropTypes.func,
  onChangeStep2: PropTypes.func.isRequired,
  onChangeCompany: PropTypes.func.isRequired,
  company: PropTypes.object,
}

Step2.defaultProps = {
  patients: [],
  setPatients: () => {},
}

export default Step2
