import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useMediaQuery from '@mui/material/useMediaQuery'
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  LinearProgress,
  Toolbar,
  Typography,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'

import ItemsSideBar from './ItemsSideBar/ItemsSideBar'
import MobileSideBar from './MobileSideBar/MobileSideBar'
import { useSelector } from 'react-redux'

const Sidebar = ({ children }) => {
  const matches = useMediaQuery('(max-width:575px)')

  const [mobileOpen, setMobileOpen] = useState(false)

  const isLoading = useSelector(state => state.pageReducer.isLoading)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  const drawerWidth = matches ? 200 : 240

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: '100%' },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Laboratorio Clifford
          </Typography>
        </Toolbar>
        {isLoading && <LinearProgress color="secondary" />}
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }, zIndex: '0' }}
        aria-label="mailbox folders"
      >
        <MobileSideBar
          mobileOpen={mobileOpen}
          drawerWidth={drawerWidth}
          onHandleDrawerToggle={handleDrawerToggle}
        />

        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
          open
        >
          <ItemsSideBar />
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          marginTop: '50px',
          padding: '0',
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

Sidebar.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Sidebar
