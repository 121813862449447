import { useDispatch, useSelector } from 'react-redux'
import Routes from './routes'
import theme from './theme'

import { ThemeProvider } from '@mui/material/styles'
import { useEffect } from 'react'
import {
  fetchRoles,
  fetchSubsidiaries,
  fetchAreas,
} from './actions/rootActions'
import { validateToken } from './actions/userActions'
import Snackbars from './components/Snackbar/Snackbar'

const App = () => {
  const dispatch = useDispatch()

  const isAuthenticated = useSelector(
    state => state.userReducer.isAuthenticated
  )

  useEffect(() => {
    dispatch(validateToken())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const fetchInitialData = () => {
      dispatch(fetchRoles())
      dispatch(fetchSubsidiaries())
      dispatch(fetchAreas())
    }

    if (isAuthenticated) {
      fetchInitialData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  return (
    <ThemeProvider theme={theme}>
      <Routes isAuthenticated={isAuthenticated} />
      <Snackbars />
    </ThemeProvider>
  )
}

export default App
