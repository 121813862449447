import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material'
import { isEmpty, partition } from 'lodash'
import { setLoading, showSnackbar } from '../../actions/pageActions'
import { ESPECIAL_CASE_TYPE_RESULTS_IDS } from '../../constants/typeResultsConstants'
import {
  GET_TYPE_RESULTS_PATH,
  GET_TYPE_RESULTS_PATH_BY_TEST_CONS,
  RECORD_RESULTS_PATH,
} from '../../constants/endpointsConstants'
import { client } from '../../utils/client'
import InputResult from '../TypeResult/InputResult/InputResult'

const styles = {
  dialog: {
    '& .MuiDialog-paper': {
      minHeight: '300px',
    },
  },
  dialogTitle: {
    textAlign: 'center',
  },
  dialogContent: { padding: '20px 24px !important' },
  dialogActions: { margin: '0 auto' },
  spinner: { position: 'absolute', top: '45%', right: '45%' },
}

const ERROR_MESSAGE = 'La suma de estos campos no puede exceder el 100%'

const RecordResultsModal = ({ test, onClose, onSave, isEdit = false }) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState({ modal: false, save: false })
  const [typeResults, setTypeResults] = useState([])
  const [calculatedTypeResults, setCalculatedTypeResults] = useState([])
  const [results, setResults] = useState(null)
  const [observation, setObservation] = useState(test?.observation || '')
  const [dependencies, setDependencies] = useState([])
  const [error, setError] = useState(false)

  const createInitialResults = typeResultsList => {
    const initialResult = {}
    if (typeResultsList.length) {
      typeResultsList.forEach(typeResult => {
        initialResult[typeResult.id] = {
          typeResultId: typeResult.id,
          result: typeResult.result || '',
        }
      })
    }
    return initialResult
  }

  const buildDependencies = calculateTypeResults => {
    const typeResultDependencies = {}
    calculateTypeResults.forEach(typeResult => {
      const calculateColumn = JSON.parse(typeResult?.calculate)
      calculateColumn?.typeResultIds?.forEach(id => {
        typeResultDependencies[id] = {
          id,
          dependencies: typeResultDependencies[id]?.dependencies
            ? [...typeResultDependencies[id]?.dependencies, typeResult.id]
            : [typeResult.id],
        }
      })
    })
    return typeResultDependencies
  }

  const validateFields = results => results.some(result => isEmpty(result))

  const changeCalculatedField = (calculateTypeResult, typeResultId, result) => {
    const calculatedResults = {}
    calculateTypeResult?.dependencies?.forEach(calculateTypeResultId => {
      const calculateTypeResult = calculatedTypeResults.find(
        tr => tr.id === calculateTypeResultId
      )
      const calculateColumn = JSON.parse(calculateTypeResult.calculate)
      const dependenceResults = calculateColumn?.typeResultIds?.map(id => {
        if (id === typeResultId) {
          return result
        }
        return results[id]?.result
      })

      if (!validateFields(dependenceResults)) {
        // eslint-disable-next-line no-eval
        const formula = eval(calculateColumn.formula)
        calculatedResults[calculateTypeResultId] = {
          typeResultId: calculateTypeResultId,
          result: String(formula(dependenceResults)),
        }
      } else {
        calculatedResults[calculateTypeResultId] = {
          typeResultId: calculateTypeResultId,
          result: '',
        }
      }
    })

    setResults({
      ...results,
      [typeResultId]: {
        typeResultId,
        result,
      },
      ...calculatedResults,
    })
  }

  const handleChangeResult = (typeResultId, result) => {
    const calculateTypeResult = dependencies[typeResultId]

    if (calculateTypeResult) {
      changeCalculatedField(calculateTypeResult, typeResultId, result)
    } else {
      setResults({
        ...results,
        [typeResultId]: {
          typeResultId,
          result,
        },
      })
    }

    validateEspecialCase(typeResultId, result)
  }

  const validateEspecialCase = (currentTypeResultId, result) => {
    let count = 0
    // HEMOGRAMA AUTOMATIZADO = 252
    if (
      test.id === 252 &&
      ESPECIAL_CASE_TYPE_RESULTS_IDS.includes(currentTypeResultId)
    ) {
      const typeResultsIds = ESPECIAL_CASE_TYPE_RESULTS_IDS.filter(
        id => id !== currentTypeResultId
      )
      typeResultsIds.forEach(typeResultId => {
        count += Number(results[typeResultId]?.result)
      })
      count += Number(result)

      if (count > 100) {
        setError(true)
      } else {
        setError(false)
      }
    }
  }

  const validResults = () => {
    const filteredResults = []
    Object.values(results || {}).forEach(result => {
      if (result?.result?.trim()) {
        filteredResults.push(result)
      }
    })
    return filteredResults
  }

  const handleSaveResults = async () => {
    setLoading(true)
    setIsLoading({ ...isLoading, save: true })
    try {
      const payload = {
        results: validResults(),
        observation,
      }
      const { data } = await client.post(
        RECORD_RESULTS_PATH(test?.testConsultationId),
        payload
      )
      setIsLoading({ ...isLoading, save: false })
      dispatch(showSnackbar(data?.message, 'success'))
      onSave(data?.data)
      onClose()
    } catch (error) {
      dispatch(showSnackbar(error?.response?.data?.message))
    }
    setLoading(false)
  }

  const handleUpdateResults = async () => {
    setLoading(true)
    setIsLoading({ ...isLoading, save: true })
    try {
      const payload = {
        results: Object.values(results),
        observation,
      }
      const { data } = await client.put(
        RECORD_RESULTS_PATH(test?.testConsultationId),
        payload
      )
      dispatch(showSnackbar(data?.message, 'success'))
      setIsLoading({ ...isLoading, save: false })
      onSave(data?.data)
      onClose()
    } catch (error) {
      dispatch(showSnackbar(error?.response?.data?.message))
    }
    setLoading(false)
  }

  useEffect(() => {
    const getTypeResults = async () => {
      setIsLoading({ ...isLoading, modal: true })
      try {
        const { data } = await client.get(
          isEdit
            ? GET_TYPE_RESULTS_PATH_BY_TEST_CONS(
                test?.id,
                test?.testConsultationId
              )
            : GET_TYPE_RESULTS_PATH(test?.id)
        )
        const [commonTypeResults, calculateTypeResults] = partition(
          data?.data,
          typeResult => isEmpty(typeResult?.calculate?.trim())
        )
        setTypeResults(commonTypeResults)
        setCalculatedTypeResults(calculateTypeResults)
        setDependencies(buildDependencies(calculateTypeResults))
        setResults(createInitialResults(data?.data))
      } catch (error) {
        dispatch(showSnackbar())
      }
      setIsLoading({ ...isLoading, modal: false })
    }
    getTypeResults()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Dialog
      sx={styles.dialog}
      fullWidth
      maxWidth="md"
      open
      onClose={onClose}
      closeAfterTransition
    >
      {isLoading.modal ? (
        <Box sx={styles.spinner}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <DialogTitle sx={styles.dialogTitle} variant="h5" component="h2">
            {`${isEdit ? 'Editar' : 'Registrar'} resutados para "${
              test?.name
            }"`}
          </DialogTitle>
          <DialogContent sx={styles.dialogContent}>
            {typeResults.length ? (
              <>
                <Grid
                  sx={{ marginBottom: '10px' }}
                  container
                  spacing={{ xs: 2, md: 3 }}
                >
                  {typeResults?.map(typeResult => (
                    <Grid key={typeResult.id} item xs={12} sm={6} md={4}>
                      <InputResult
                        error={
                          ESPECIAL_CASE_TYPE_RESULTS_IDS.includes(typeResult.id)
                            ? error
                            : false
                        }
                        typeResult={typeResult}
                        value={results[typeResult.id]?.result}
                        onChangeResult={handleChangeResult}
                      />
                    </Grid>
                  ))}
                </Grid>

                {!!calculatedTypeResults.length && (
                  <Divider sx={{ marginBottom: '10px' }} />
                )}

                <Grid container spacing={{ xs: 2, md: 3 }}>
                  {calculatedTypeResults?.map(typeResult => (
                    <Grid key={typeResult.id} item xs={12} sm={6} md={4}>
                      <InputResult
                        disabled
                        typeResult={typeResult}
                        value={results[typeResult.id]?.result}
                        onChangeResult={handleChangeResult}
                      />
                    </Grid>
                  ))}
                </Grid>

                <Grid sx={{ marginTop: '10px' }} container spacing={2}>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      label="Observaciones"
                      fullWidth
                      variant="filled"
                      defaultValue={observation}
                      onChange={event => setObservation(event.target.value)}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <Alert severity="warning">
                No existe tipos de resultados para este análisis.
              </Alert>
            )}
            {error && (
              <div
                style={{ marginTop: '10px', color: 'red', textAlign: 'center' }}
              >
                {ERROR_MESSAGE}
              </div>
            )}
          </DialogContent>
          <DialogActions sx={styles.dialogActions}>
            <Button
              sx={{ margin: '5px' }}
              color="primary"
              variant="outlined"
              onClick={onClose}
            >
              Cancelar
            </Button>

            <Button
              sx={{ margin: '5px' }}
              disabled={isLoading.save || (!validResults()?.length && !isEdit)}
              color="primary"
              variant="contained"
              onClick={isEdit ? handleUpdateResults : handleSaveResults}
            >
              Guardar
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

RecordResultsModal.propTypes = {
  test: PropTypes.instanceOf(Object).isRequired,
  isEdit: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
}

RecordResultsModal.defaultProps = {
  onClose: () => {},
  onSave: () => {},
}

export default RecordResultsModal
