import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import useMediaQuery from '@mui/material/useMediaQuery'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from '@mui/material'

const styles = {
  textField: {
    marginBlock: { sm: '10px', xs: '0px' },
  },
  buttonGroup: {
    margin: '0 auto',
  },
  button: {
    margin: '10px',
  },
  grid: {
    marginBlock: { sm: '0px', xs: '10px' },
  },
}

const emptyCompany = {
  name: '',
  firstNameManager: '',
  lastNameManager: '',
  phoneNumber: '',
  address: '',
  notes: '',
}

const defaultError = {
  name: {
    error: false,
    message: '',
  },
  firstNameManager: {
    error: false,
    message: '',
  },
  lastNameManager: {
    error: false,
    message: '',
  },
  phoneNumber: {
    error: false,
    message: '',
  },
  address: {
    error: false,
    message: '',
  },
  notes: {
    error: false,
    message: '',
  },
}

const ERROR_MESSAGES = {
  name: 'Nombre requerido',
}

const CompanyModal = ({ open, company, onClose, onSubmit }) => {
  const initialCompany = {
    name: company?.name || '',
    firstNameManager: company?.firstNameManager || '',
    lastNameManager: company?.lastNameManager || '',
    phoneNumber: company?.phoneNumber || '',
    address: company?.address || '',
    notes: company?.notes || '',
  }
  const [companyFields, setCompanyFields] = useState(initialCompany)
  const [validFields, setValidFields] = useState(defaultError)

  const matches = useMediaQuery('(max-width:575px)')
  const sizeInput = matches ? 'small' : 'normal'

  const handleCloseModal = () => {
    setCompanyFields(emptyCompany)
    onClose()
  }

  const handleFieldChange = prop => event => {
    const {
      target: { value },
    } = event

    if (prop === 'phoneNumber') {
      if (Number(value) >= 0) {
        setCompanyFields({
          ...companyFields,
          [prop]: value,
        })
      }
    } else {
      setCompanyFields({
        ...companyFields,
        [prop]: value,
      })
    }
  }

  const handleBlur = prop => event => {
    const { target } = event

    const value = target?.value.trim()

    setValidFields({
      ...validFields,
      [prop]: {
        error: false,
        message: '',
      },
    })

    if (!value.length) {
      setValidFields({
        ...validFields,
        [prop]: {
          error: true,
          message: ERROR_MESSAGES[prop],
        },
      })
    }
  }

  const disabledButton = useMemo(
    () => !companyFields.name?.trim(),
    [companyFields.name]
  )

  const handleSubmit = async () => {
    if (await onSubmit(companyFields)) {
      setCompanyFields(emptyCompany)
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <DialogTitle sx={{ textAlign: 'center' }} variant="h6" component="h2">
        {company.id ? 'Editar Compañia' : 'Crear Compañia'}
      </DialogTitle>
      <DialogContent>
        <Grid sx={styles.grid} container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              sx={styles.textField}
              size={sizeInput}
              error={validFields.name.error}
              fullWidth
              helperText={validFields.name.message}
              required
              label="Nombre"
              placeholder="Ingrese el nombre"
              value={companyFields.name}
              onChange={handleFieldChange('name')}
              onBlur={handleBlur('name')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              sx={styles.textField}
              size={sizeInput}
              fullWidth
              label="Nombre Encargado"
              placeholder="Ingrese el nombre del encargado"
              value={companyFields.firstNameManager}
              onChange={handleFieldChange('firstNameManager')}
            />
          </Grid>
        </Grid>
        <Grid sx={styles.grid} container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              sx={styles.textField}
              size={sizeInput}
              fullWidth
              label="Apellidos Encargado"
              placeholder="Ingrese los apellidos del encargado"
              value={companyFields.lastNameManager}
              onChange={handleFieldChange('lastNameManager')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              sx={styles.textField}
              type="number"
              size={sizeInput}
              fullWidth
              label="Celular Encargado"
              placeholder="Ingrese el celular del encargado"
              value={companyFields.phoneNumber}
              onChange={handleFieldChange('phoneNumber')}
            />
          </Grid>
        </Grid>
        <Grid sx={styles.grid} container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              sx={styles.textField}
              size={sizeInput}
              fullWidth
              label="Dirección"
              placeholder="Ingrese la dirección de la empresa"
              value={companyFields.address}
              onChange={handleFieldChange('address')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              sx={styles.textField}
              size={sizeInput}
              fullWidth
              label="Notas"
              placeholder="Ingrese alguna nota"
              value={companyFields.notes}
              onChange={handleFieldChange('notes')}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={styles.buttonGroup}>
        <Button
          sx={styles.button}
          variant="outlined"
          color="primary"
          onClick={handleCloseModal}
        >
          Cancelar
        </Button>
        <Button
          sx={styles.button}
          variant="contained"
          color="primary"
          disabled={disabledButton}
          onClick={handleSubmit}
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

CompanyModal.propTypes = {
  open: PropTypes.bool,
  company: PropTypes.instanceOf(Object),
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
}

CompanyModal.defaultProps = {
  open: false,
  company: {},
  onClose: () => {},
  onSubmit: () => {},
}

export default CompanyModal
