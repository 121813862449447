import React from 'react'
import { client } from '../../utils/client'
import { useDispatch } from 'react-redux'
import { setLoading, showSnackbar } from '../../actions/pageActions'
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog'
import { TESTS_PATH } from '../../constants/endpointsConstants'
import { fetchingRemoveTestFromArea } from '../../slices/rootReducer'

const DeleteTest = ({ test }) => {
  const dispatch = useDispatch()

  const deleteTest = () => {
    dispatch(setLoading(true))
    client
      .delete(`${TESTS_PATH}/${test.id}`)
      .then(res => {
        dispatch(fetchingRemoveTestFromArea(test))
        dispatch(setLoading(false))
        dispatch(showSnackbar(res.message, 'success'))
      })
      .catch(() => {
        dispatch(setLoading(false))
        dispatch(showSnackbar('Error al eliminar el análisis', 'error'))
      })
  }
  return (
    <ConfirmDialog
      buttonOpen={'Eliminar'}
      title="Eliminar análisis"
      body={`¿Deseas eliminar el análisis "${test.name}"?`}
      onConfirm={deleteTest}
    />
  )
}

export default DeleteTest
