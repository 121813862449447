import { useEffect, useRef } from 'react'

export default function ScrollToHereOnMount() {
  const myRef = useRef(0)
  useEffect(() => {
    if (myRef.current) {
      myRef.current.scrollIntoView()
    }
  }, [])

  return <div ref={myRef} />
}
