import React from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import { client } from '../../utils/client'
import { useDispatch } from 'react-redux'
import { setLoading, showSnackbar } from '../../actions/pageActions'
import { IconButton } from '@mui/material'
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog'
import { DOCTORS_PATH } from '../../constants/endpointsConstants'

const DeleteDoctor = ({ doctor, onDelete }) => {
  const dispatch = useDispatch()

  const deleteDoctor = () => {
    dispatch(setLoading(true))
    client
      .delete(`${DOCTORS_PATH}/${doctor.id}`)
      .then(() => {
        dispatch(setLoading(false))
        dispatch(showSnackbar('Doctor eliminado', 'success'))
        onDelete(doctor.id)
      })
      .catch(() => {
        dispatch(setLoading(false))
        dispatch(showSnackbar('Error al eliminar el doctor', 'error'))
      })
  }
  return (
    <ConfirmDialog
      buttonOpen={
        <IconButton color="error">
          <DeleteIcon />
        </IconButton>
      }
      title="Eliminar doctor"
      body={`¿Deseas eliminar a "${doctor.name}"?`}
      onConfirm={deleteDoctor}
    />
  )
}

export default DeleteDoctor
