import moment from 'moment'

export const getBirthday = birthday => {
  return moment().diff(birthday, 'years')
}

export const formatBirthday = (birthday, agePatient) => {
  let formatted = ` ${moment(birthday).format('L')}`

  switch (agePatient) {
    case 0:
      const days = moment().diff(birthday, 'days')
      if (days <= 7) {
        formatted += ` (${days} ${days === 1 ? 'dia' : 'días'})`
      }

      if (days <= 31 && days > 7) {
        const weeks = moment().diff(birthday, 'weeks')
        formatted += ` (${weeks} ${weeks === 1 ? 'semana' : 'semanas'})`
      }

      if (days > 31) {
        const months = moment().diff(birthday, 'months')
        formatted += ` (${months} ${months === 1 ? 'mes' : 'meses'})`
      }

      break
    case 1:
      formatted += ` (${agePatient} año)`
      break

    default:
      formatted += ` (${agePatient} años)`
      break
  }

  return formatted
}
