import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ViewModuleIcon from '@mui/icons-material/ViewModule'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading, showSnackbar } from '../../actions/pageActions'
import { client } from '../../utils/client'
import {
  TESTS_PATH,
  TYPE_RESULT_PATH,
} from '../../constants/endpointsConstants'
import { groupBy, isEmpty } from 'lodash'
import TypeResultList from '../TypeResult/TypeResultList/TypeResultList'
import ModalTest from '../ModalTest/ModalTest'
import { ADMIN, MANAGER } from '../../constants/rolesConstants'
import { addTestToAreas } from '../../actions/rootActions'
import { fetchingRemoveTestFromArea } from '../../slices/rootReducer'
import DeleteTest from '../Test/DeleteTest'
import SortTestsModal from '../SortTestsModal/SortTestsModal'

const SettingTestsByArea = () => {
  const dispatch = useDispatch()
  const areas = useSelector(state => state.rootReducer.areas)
  const role = useSelector(state => state.userReducer.user?.role)
  const [typeResultsByTestId, setTypeResulstByTestId] = useState({})
  const [typeResultsSelected, setTypeResultsSelected] = useState(null)
  const [testSelected, setTestSelected] = useState(null)
  const [openTypeResultsModal, setOpenTypeResultsModal] = useState(false)
  const [testEditSelected, setTestEditSelected] = useState(null)
  const [openNewEditTest, setOpenNewEditTest] = useState(false)
  const [openSortModal, setOpenSortModal] = useState(false)
  const [areaSelected, setAreaSelected] = useState(null)

  const getTypeResults = async () => {
    handleLoading(true)
    client
      .get(TYPE_RESULT_PATH)
      .then(response => {
        const _typeResultByTestId = groupBy(
          response.data.data,
          typeResults => typeResults.testId
        )
        setTypeResulstByTestId(_typeResultByTestId)
      })
      .catch(error => {
        dispatch(showSnackbar())
      })
    handleLoading(false)
  }
  useEffect(() => {
    getTypeResults()
    // eslint-disable-next-line
  }, [])

  const handleLoading = value => dispatch(setLoading(value))

  const handleOpenTypeResults = (typeResults, test) => {
    setTestSelected(test)
    setTypeResultsSelected(typeResults?.sort((a, b) => a?.order - b?.order))
    setOpenTypeResultsModal(true)
  }

  const handleClose = () => {
    setOpenTypeResultsModal(false)
    setTypeResultsSelected(null)
  }

  const handleChagesOnTypeResults = (typeResult, action) => {
    const newTypeResultsByTestId = { ...typeResultsByTestId }
    // eslint-disable-next-line default-case
    switch (action) {
      case 'insert':
        if (newTypeResultsByTestId[typeResult.testId]) {
          newTypeResultsByTestId[typeResult.testId].push(typeResult)
        } else {
          newTypeResultsByTestId[typeResult.testId] = [typeResult]
        }

        break
      case 'update':
        const listUpdate = newTypeResultsByTestId[typeResult.testId].map(item =>
          item.id === typeResult.id ? typeResult : item
        )
        newTypeResultsByTestId[typeResult.testId] = listUpdate
        break
    }
    setTypeResulstByTestId(newTypeResultsByTestId)
    setTypeResultsSelected(newTypeResultsByTestId[typeResult.testId])
  }

  const handleCreateTest = async newTest => {
    dispatch(setLoading(true))
    let success = false
    try {
      const {
        data: { message, data: test },
      } = await client.post(`${TESTS_PATH}`, newTest)

      dispatch(addTestToAreas(test))
      dispatch(showSnackbar(message, 'success'))
      success = true
    } catch (error) {
      dispatch(showSnackbar(error?.response?.data?.message))
    }

    dispatch(setLoading(false))
    return success
  }

  const handleEditTest = async (updateTest, logicDelete) => {
    let success = false
    try {
      dispatch(setLoading(true))

      const {
        data: { message, data: test },
      } = await client.put(
        `${TESTS_PATH}/${testEditSelected?.id}`,
        updateTest,
        { params: { logicDelete } }
      )
      logicDelete && getTypeResults()
      handleCloseModalTest()
      dispatch(fetchingRemoveTestFromArea(testEditSelected))
      dispatch(addTestToAreas(test))
      dispatch(setLoading(false))
      dispatch(showSnackbar(message, 'success'))
      success = true
    } catch (error) {
      dispatch(setLoading(false))
      dispatch(showSnackbar(error?.response?.data?.message))
    }
    return success
  }

  const handleSelectTest = test => {
    setTestEditSelected(test)
    setOpenNewEditTest(true)
  }

  const handleCloseModalTest = () => {
    setTestEditSelected({})
    setOpenNewEditTest(false)
  }

  const handleOpenSortOrderModal = area => {
    setAreaSelected(area)
    setOpenSortModal(true)
  }

  const handleCloseSortOrderModal = () => {
    setOpenSortModal(false)
    setAreaSelected(null)
  }

  const handleOpenModalTest = () => setOpenNewEditTest(true)

  return (
    <>
      {[ADMIN, MANAGER].includes(role.name) && (
        <Grid container justifyContent="space-between">
          <Typography variant="h4">Análisis por Área</Typography>
          <Button
            sx={{ marginBottom: '10px' }}
            variant="contained"
            startIcon={<AddCircleIcon />}
            onClick={handleOpenModalTest}
          >
            Nuevo Análisis
          </Button>
        </Grid>
      )}

      {areas.map(area => (
        <Accordion key={area.id} TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ width: '70%', flexShrink: 0 }}>
              {area.name}
            </Typography>
            <Typography color="text.secondary" align="right">
              {area?.Tests?.length} análisis
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ textAlign: 'end', marginBottom: '8px' }}>
              {area?.Tests?.length && (
                <Button
                  size="small"
                  startIcon={<ViewModuleIcon />}
                  onClick={() => handleOpenSortOrderModal(area)}
                  variant="outlined"
                >
                  Cambiar Orden
                </Button>
              )}
            </Box>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              {area?.Tests?.map(test => (
                <Grid key={test.id} item xs={12} sm={6} md={4}>
                  <Card variant="outlined">
                    <CardContent>
                      <Typography variant="h6">{test.name}</Typography>
                      <Typography color="text.secondary">
                        {typeResultsByTestId[test?.id]?.length || 0}{' '}
                        resultado(s)
                      </Typography>
                      <Typography color="text.secondary">
                        Costo Sug. {test?.suggestedCost} Bs.
                      </Typography>
                    </CardContent>
                    <CardActions style={{ justifyContent: 'center' }}>
                      <ButtonGroup
                        variant="outlined"
                        aria-label="outlined button group"
                      >
                        <Button
                          size="small"
                          onClick={() =>
                            handleOpenTypeResults(
                              typeResultsByTestId[test?.id]
                                ? typeResultsByTestId[test?.id]
                                : [],
                              test
                            )
                          }
                        >
                          Ver
                        </Button>
                        {ADMIN === role.name && (
                          <>
                            <Button
                              size="small"
                              onClick={() => handleSelectTest(test)}
                            >
                              Editar
                            </Button>
                            <Button size="small" color="secondary">
                              <DeleteTest test={test} />
                            </Button>
                          </>
                        )}
                      </ButtonGroup>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
      {openTypeResultsModal && (
        <TypeResultList
          open={openTypeResultsModal}
          typeResults={typeResultsSelected}
          test={testSelected}
          handleChagesOnTypeResults={handleChagesOnTypeResults}
          onClose={() => handleClose()}
          onChangeTypeResults={setTypeResultsSelected}
        />
      )}
      {openNewEditTest && (
        <ModalTest
          open={openNewEditTest}
          onClose={handleCloseModalTest}
          test={testEditSelected}
          onSubmit={
            isEmpty(testEditSelected) ? handleCreateTest : handleEditTest
          }
        />
      )}
      {openSortModal && (
        <SortTestsModal
          open={openSortModal}
          tests={areaSelected?.Tests}
          areaId={areaSelected?.id}
          onClose={handleCloseSortOrderModal}
        />
      )}
    </>
  )
}

export default SettingTestsByArea
