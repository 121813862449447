import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  errorMessage: '',
  isAuthenticated: false,
  isLoading: false,
  user: {},
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    fetchingUser: state => {
      state.errorMessage = ''
      state.isAuthenticated = false
      state.isLoading = true
      state.user = {}
    },
    fetchingUserSuccess: (state, action) => {
      const { payload: user } = action

      state.errorMessage = ''
      state.isAuthenticated = true
      state.isLoading = false
      state.user = user
    },
    fetchingUserFailure: (state, action) => {
      const { payload: message } = action

      state.errorMessage = message
      state.isAuthenticated = false
      state.isLoading = false
      state.user = {}
    },
    logoutUser: state => {
      state.errorMessage = ''
      state.isAuthenticated = false
      state.user = {}
    },
  },
})

export const {
  fetchingUser,
  fetchingUserSuccess,
  fetchingUserFailure,
  logoutUser,
} = userSlice.actions

export default userSlice.reducer
