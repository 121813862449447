import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  DialogTitle,
  TextField,
} from '@mui/material'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setLoading, showSnackbar } from '../../actions/pageActions'
import { CHANGE_PASSWORD_PATH } from '../../constants/endpointsConstants'
import { client } from '../../utils/client'

const ChangePassword = ({ open, onClose }) => {
  const dispatch = useDispatch()

  const styles = {
    textField: {
      marginBlock: '10px',
    },
    buttonGroup: {
      margin: '0 auto',
    },
    button: {
      margin: '10px',
    },
    grid: {
      marginBlock: { sm: '0px', xs: '10px' },
    },
  }

  const defaultError = {
    oldPassword: {
      error: false,
      message: '',
    },
    newPassword: {
      error: false,
      message: '',
    },
    confirmPassword: {
      error: false,
      message: '',
    },
  }

  const ERROR_MESSAGES = {
    oldPassword: 'Anterior contraseña requerida',
    newPassword: 'Nueva contraseña requerida',
    confirmPassword: 'Es necesario confirmar la contraseña',
  }

  const emptyPassword = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  }

  const [passwordFields, setPasswordFields] = useState(emptyPassword)
  const [validFields, setValidFields] = useState(defaultError)

  const handleFieldChange = prop => event => {
    const {
      target: { value },
    } = event

    setPasswordFields({
      ...passwordFields,
      [prop]: value,
    })
  }

  const handleBlur = prop => event => {
    const { target } = event

    const value = target?.value.trim()

    setValidFields({
      ...validFields,
      [prop]: {
        error: false,
        message: '',
      },
    })

    if (!value.length) {
      setValidFields({
        ...validFields,
        [prop]: {
          error: true,
          message: ERROR_MESSAGES[prop],
        },
      })
    } else {
      if (prop === 'newPassword' && value.length < 8) {
        setValidFields({
          ...validFields,
          [prop]: {
            error: true,
            message: 'La contrasela debe de tener 8 caracteres minimamente',
          },
        })
      }
      if (prop === 'confirmPassword' && value !== passwordFields.newPassword) {
        setValidFields({
          ...validFields,
          [prop]: {
            error: true,
            message: 'La contrasela no coincide',
          },
        })
      }

      setPasswordFields({
        ...passwordFields,
        [prop]: value,
      })
    }
  }

  const disabledButton = () => {
    return (
      passwordFields.oldPassword &&
      passwordFields.newPassword.length >= 8 &&
      passwordFields.newPassword === passwordFields.confirmPassword
    )
  }

  const handleChangePassword = async values => {
    let success = false
    try {
      dispatch(setLoading(true))
      const {
        data: { message },
      } = await client.post(CHANGE_PASSWORD_PATH, values)

      dispatch(showSnackbar(message, 'success'))
      dispatch(setLoading(false))
      success = true
    } catch (error) {
      dispatch(setLoading(false))
      dispatch(showSnackbar(error?.response?.data?.message))
    }
    return success
  }

  const handleCloseModal = () => {
    onClose()
    setPasswordFields(emptyPassword)
    setValidFields(defaultError)
  }

  const handleClickButton = async () => {
    if (await handleChangePassword(passwordFields)) {
      onClose()
    }
  }
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle sx={{ textAlign: 'center' }}>Cambiar contraseña</DialogTitle>
      <DialogContent>
        <Grid sx={styles.grid} container>
          <Grid item xs={12}>
            <TextField
              fullWidth
              sx={styles.textField}
              error={validFields.oldPassword.error}
              type="password"
              helperText={validFields.oldPassword.message}
              required
              label="Contraseña Antigua"
              placeholder="Ingrese la contraseña antigua"
              value={passwordFields.oldPassword}
              onChange={handleFieldChange('oldPassword')}
              onBlur={handleBlur('oldPassword')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              sx={styles.textField}
              error={validFields.newPassword.error}
              type="password"
              helperText={validFields.newPassword.message}
              required
              label="Nueva contraseña"
              placeholder="Ingrese su nueva contraseña"
              value={passwordFields.newPassword}
              onChange={handleFieldChange('newPassword')}
              onBlur={handleBlur('newPassword')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              sx={styles.textField}
              error={validFields.confirmPassword.error}
              type="password"
              helperText={validFields.confirmPassword.message}
              required
              label="Confirmar contraseña"
              placeholder="Repita la nueva contraseña"
              value={passwordFields.confirmPassword}
              onChange={handleFieldChange('confirmPassword')}
              onBlur={handleBlur('confirmPassword')}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={styles.buttonGroup}>
        <Button sx={styles.button} onClick={handleCloseModal}>
          Cancelar
        </Button>
        <Button
          sx={styles.button}
          variant="contained"
          color="primary"
          disabled={!disabledButton()}
          onClick={handleClickButton}
          autoFocus
        >
          Cambiar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ChangePassword
