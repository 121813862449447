import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import useMediaQuery from '@mui/material/useMediaQuery'
import PropTypes from 'prop-types'
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { formatBirthday, getBirthday } from '../../../utils/patient'
import ScrollToHereOnMount from '../../../common/ScrollToHereOnMount/ScrollToHereOnMount'

import { ADMIN } from '../../../constants/rolesConstants'
import SelectDoctor from '../../SelectDoctor/SelectDoctor'

const styles = {
  fieldName: {
    maxHeight: '25px',
    fontWeight: 800,
  },
  fieldValue: {
    maxHeight: '25px',
    fontWeight: 600,
  },
  patientData: {
    padding: { sm: '10px 50px', xs: '10px 0' },
  },
  subsidiary: {
    display: 'flex',
    alignItems: 'center',
  },
  dropdown: {
    marginBlock: '10px',
  },
  textField: {
    marginBlock: '10px',
  },
}

const PaginationTable = ({ count, matches, totalPrice }) => (
  <div style={{ marginRight: `${matches ? '20px' : '35px'}` }}>
    <span style={{ fontWeight: '600', marginBottom: '5px', display: 'block' }}>
      Nº de Ánalisis: {count}
    </span>
    <span style={{ fontWeight: '600' }}>Costo Total: {totalPrice} Bs.</span>
  </div>
)

const Step3 = ({
  patient,
  tests,
  subsidiaryId,
  company,
  assured,
  requester,
  totalPrice,
  onChangeStep3,
  doctor,
}) => {
  const {
    name,
    lastNameFather,
    lastNameMother,
    identityCard,
    birthday,
    cellPhone,
    landline,
  } = patient

  const matches = useMediaQuery('(max-width:575px)')
  const subsidiaries = useSelector(state => state.rootReducer.subsidiaries)
  const loginUserRole = useSelector(state => state.userReducer.user.role)
  const isAdmin = loginUserRole.name === ADMIN
  const agePatient = getBirthday(birthday)
  const showAssuredData = company && assured

  const [consultationFields, setConsultationFields] = useState({
    subsidiaryId: subsidiaryId,
    requester: requester ?? '',
  })

  const handleFieldChange = prop => event => {
    const {
      target: { value },
    } = event
    const newFields = {
      ...consultationFields,
      [prop]: value,
    }

    setConsultationFields(newFields)
    onChangeStep3(newFields)
  }

  const handleSelectDoctor = doctor => {
    const newFields = {
      ...consultationFields,
      doctorId: doctor?.id ? doctor.id : null,
      requester: doctor?.id ? null : doctor?.name,
    }

    setConsultationFields(newFields)
    onChangeStep3(newFields)
  }

  const columns = [
    { field: 'name', headerName: 'Análisis', flex: 0.6, minWidth: 150 },
    { field: 'cost', headerName: 'Costo', flex: 0.4, minWidth: 70 },
    { field: 'comment', headerName: 'Comentario', flex: 0.8, minWidth: 200 },
  ]

  return (
    <div
      className="step-3"
      style={{ padding: `${matches ? '20px 0' : '20px'}` }}
    >
      <ScrollToHereOnMount />
      <div className="patient-info">
        <h3 style={{ marginBlock: 0 }}>DATOS PACIENTE: </h3>
        <Grid container spacing={2} sx={styles.patientData}>
          <Grid container spacing={1} item xs={12} sm={8} md={8}>
            <Grid item xs={6} sm={6} md={6}>
              <h4 style={styles.fieldName}>Nombre Completo:</h4>
              <h4 style={styles.fieldName}>CI:</h4>
              <h4 style={styles.fieldName}>Fecha de Nacimiento:</h4>
              {cellPhone && <h4 style={styles.fieldName}>Celular:</h4>}
              {landline && <h4 style={styles.fieldName}>Teléfono:</h4>}
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <h4 style={styles.fieldValue}>
                {`${name || ''} ${lastNameFather || ''} ${
                  lastNameMother || ''
                }`}
              </h4>
              <h4 style={styles.fieldValue}>{identityCard}</h4>
              <h4 style={styles.fieldValue}>
                {formatBirthday(birthday, agePatient)}
              </h4>
              {cellPhone && <h4 style={styles.fieldValue}>{cellPhone}</h4>}
              {landline && <h4 style={styles.fieldValue}>{landline}</h4>}
            </Grid>
          </Grid>

          <Grid item xs={12} sm={4} md={4} sx={styles.subsidiary}>
            <Grid container>
              <Grid item xs={12}>
                {isAdmin && (
                  <FormControl fullWidth sx={styles.dropdown}>
                    <InputLabel>Sucursal</InputLabel>
                    <Select
                      value={consultationFields.subsidiaryId ?? ''}
                      label="Sucursal"
                      onChange={handleFieldChange('subsidiaryId')}
                      defaultValue=""
                    >
                      {subsidiaries.map(subsidiary => (
                        <MenuItem key={subsidiary.id} value={subsidiary.id}>
                          {subsidiary.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12}>
                <SelectDoctor
                  onDoctorSelected={handleSelectDoctor}
                  defaultDoctor={doctor}
                  requester={requester}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      {showAssuredData && (
        <div className="patient-info">
          <h3 style={{ marginBlock: 0 }}>DATOS TITULAR: </h3>
          <Grid container spacing={2} sx={styles.patientData}>
            <Grid container spacing={1} item xs={12} sm={8} md={8}>
              <Grid item xs={6} sm={6} md={6}>
                <h4 style={styles.fieldName}>Empresa:</h4>
                <h4 style={styles.fieldName}>Nombre Completo:</h4>
                <h4 style={styles.fieldName}>CI:</h4>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <h4 style={styles.fieldValue}>{company?.name}</h4>
                <h4 style={styles.fieldValue}>
                  {`${assured?.name || ''} ${assured?.lastNameFather || ''} ${
                    assured?.lastNameMother || ''
                  }`}
                </h4>
                <h4 style={styles.fieldValue}>{assured?.identityCard}</h4>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
      <div className="tests-info">
        <h3 style={{ marginBlock: 0 }}>ANÁLISIS: </h3>
        <div
          style={{
            marginTop: '20px',
            height: '280px',
          }}
        >
          <DataGrid
            sx={{ width: '100%' }}
            rows={tests}
            columns={columns}
            components={{
              Pagination: () => (
                <PaginationTable
                  count={tests.length}
                  matches={matches}
                  totalPrice={totalPrice}
                />
              ),
            }}
            disableColumnReorder={true}
          />
        </div>
      </div>
    </div>
  )
}

Step3.propTypes = {
  patient: PropTypes.instanceOf(Object),
  tests: PropTypes.instanceOf(Array),
  totalPrice: PropTypes.number,
  onChangeStep3: PropTypes.func.isRequired,
  subsidiaryId: PropTypes.number,
  requester: PropTypes.string,
  company: PropTypes.object,
  assured: PropTypes.object,
}

Step3.defaultProps = {
  patient: {},
  tests: [],
  totalPrice: 0,
}

export default Step3
