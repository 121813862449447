import React, { useState } from 'react'
import { DataGrid as DT } from '@mui/x-data-grid'

const DataGrid = ({ columns, rows, loading }) => {
  const [pageSize, setPageSize] = useState(10)
  return (
    <DT
      rows={rows}
      columns={columns}
      hideFooterSelectedRowCount
      onPageSizeChange={newPageSize => setPageSize(newPageSize)}
      rowsPerPageOptions={[10, 25, 50]}
      pageSize={pageSize}
      autoHeight
      disableColumnReorder={true}
      loading={loading}
      sx={{ width: '100%' }}
    />
  )
}

export default DataGrid
