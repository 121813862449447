import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  roles: [],
  subsidiaries: [],
  areas: [],
}

const rootSlice = createSlice({
  name: 'root',
  initialState,
  reducers: {
    fetchingRolesSuccess: (state, action) => {
      const { payload: roles } = action
      state.roles = roles
    },
    fetchingSubsidiariesSuccess: (state, action) => {
      const { payload: subsidiaries } = action
      state.subsidiaries = subsidiaries
    },
    fetchingAreasSuccess: (state, action) => {
      const { payload: areas } = action
      state.areas = areas
    },
    fetchingAddTestToArea: (state, action) => {
      const { payload: newTest } = action
      state.areas
        .filter(area => area.id === newTest.areaId)[0]
        .Tests.push(newTest)
    },
    fetchingRemoveTestFromArea: (state, action) => {
      const { payload: testToRemove } = action
      const newArray = state.areas
        .filter(area => area.id === testToRemove.areaId)[0]
        .Tests.filter(test => test.id !== testToRemove.id)
      state.areas.filter(area => area.id === testToRemove.areaId)[0].Tests =
        newArray
    },
  },
})

export const {
  fetchingRolesSuccess,
  fetchingSubsidiariesSuccess,
  fetchingAreasSuccess,
  fetchingAddTestToArea,
  fetchingRemoveTestFromArea,
} = rootSlice.actions

export default rootSlice.reducer
