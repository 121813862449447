import { Button, Grid, TextField, Box, InputAdornment } from '@mui/material'
import React, { useState } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import DateAdapter from '@mui/lab/AdapterMoment'
import { DatePicker, LocalizationProvider } from '@mui/lab'

export const SearchBar = ({ items, onSubmit }) => {
  const [searchFields, setSearchFields] = useState({})

  const handleFieldChange = prop => event => {
    const {
      target: { value },
    } = event

    setSearchFields({
      ...searchFields,
      [prop]: value.trim(),
    })
  }

  const handleClickSearch = async () => onSubmit(searchFields)

  const handlePressEnter = event => {
    if (event.key === 'Enter') {
      handleClickSearch()
    }
  }

  return (
    <Grid
      container
      sx={{ flexDirection: { xs: 'column', md: 'row' }, marginBottom: '8px' }}
      justifyContent="space-between"
      spacing={1}
    >
      {items.map(item => (
        <Grid
          item
          key={item.label}
          xs={12}
          sm={12}
          md={9 / items.length}
          xl={10.5 / items.length}
        >
          {item.type === 'date' ? (
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DatePicker
                label={item.label}
                value={searchFields[item.prop] || null}
                onChange={date => {
                  setSearchFields({
                    ...searchFields,
                    [item.prop]: date?.format('YYYY-MM-DD'),
                  })
                }}
                renderInput={params => (
                  <TextField
                    fullWidth
                    size="small"
                    {...params}
                    onKeyDown={handlePressEnter}
                  />
                )}
              />
            </LocalizationProvider>
          ) : (
            <TextField
              fullWidth
              label={item.label}
              size="small"
              variant="outlined"
              type={item.type}
              onChange={handleFieldChange(item.prop)}
              onKeyDown={handlePressEnter}
              InputProps={
                item.startAdornment
                  ? {
                      startAdornment: (
                        <InputAdornment position="start">
                          {item.startAdornment}
                        </InputAdornment>
                      ),
                    }
                  : {}
              }
            />
          )}
        </Grid>
      ))}
      <Grid item xs={12} sm={12} md={3} xl={1.5}>
        <Box sx={{ textAlign: { xs: 'end', sm: 'end' } }}>
          <Button
            variant="contained"
            sx={{ width: '130px' }}
            startIcon={<SearchIcon />}
            onClick={handleClickSearch}
          >
            Buscar
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}
