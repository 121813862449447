import React from 'react'
import { Redirect, Route } from 'react-router-dom'

const PrivateRouteWrapper = ({
  component: Component,
  layout: Layout,
  isAllowed,
  ...rest
}) => {
  return isAllowed ? (
    <Route
      {...rest}
      component={() => (
        <Layout>
          <Component />
        </Layout>
      )}
    />
  ) : (
    <Redirect
      to={{
        pathname: '/sign-in',
      }}
    />
  )
}

export default PrivateRouteWrapper
