import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import useMediaQuery from '@mui/material/useMediaQuery'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Autocomplete,
  Chip,
  FormHelperText,
} from '@mui/material'

import { INPUTS_TYPE } from '../../constants/inputsConstants'

const styles = {
  textField: {
    marginBlock: { sm: '10px', xs: '0px' },
  },
  buttonGroup: {
    margin: '0 auto',
  },
  button: {
    margin: '10px',
  },
  grid: {
    marginBlock: { sm: '0px', xs: '10px' },
  },
}

const defaultError = {
  name: {
    error: false,
    message: '',
  },
  custom_reference_range: {
    error: false,
    message: '',
  },
  reference_range: {
    error: false,
    message: '',
  },
  reference_range_2: {
    error: false,
    message: '',
  },
  reference_range_unit: {
    error: false,
    message: '',
  },
  method: {
    error: false,
    message: '',
  },
  type_of_input: {
    error: false,
    message: '',
  },
  possible_values: {
    error: false,
    message: '',
  },
  testId: {
    error: false,
    message: '',
  },
}

const ERROR_MESSAGES = {
  name: 'Nombre es requerido',
  custom_reference_range: 'Rango de referencia personalizado requerido',
  reference_range: 'Rango de referencia inicial requerido',
  reference_range_2: 'Rango de referencia final requerido',
  reference_range_unit: 'Unidad de rango de referencia requerido',
  method: 'Método requerido',
  type_of_input: 'Tipo de entrada requerido',
  possible_values: 'Posibles opciones requerido',
  testId: 'Análisis requerido',
}

const ModalTypeResult = ({ open, typeResult, test, onClose, onSubmit }) => {
  const emptyTypeResult = {
    name: '',
    custom_reference_range: '',
    reference_range: '',
    reference_range_2: '',
    reference_range_unit: '',
    method: '',
    type_of_input: 'number',
    possible_values: [],
    testId: test?.id,
  }
  const initialTypeResult = {
    name: typeResult?.name || '',
    reference_range: typeResult?.reference_range || '',
    custom_reference_range: typeResult?.custom_reference_range || '',
    reference_range_2: typeResult?.reference_range_2 || '',
    reference_range_unit: typeResult?.reference_range_unit || '',
    method: typeResult?.method || '',
    type_of_input: typeResult?.type_of_input || 'number',
    possible_values: typeResult?.possible_values
      ? JSON.parse(typeResult?.possible_values)
      : [],
    testId: test?.id || '',
  }

  const isLoading = useSelector(state => state.pageReducer.isLoading)

  const [typeResultFields, setTypeResultFields] = useState(initialTypeResult)
  const [validFields, setValidFields] = useState(defaultError)

  const matches = useMediaQuery('(max-width:575px)')
  const sizeInput = matches ? 'small' : 'normal'

  const handleFieldChange = (value, prop) => {
    let newTypeResultFields = {}
    if (prop === 'type_of_input') {
      if (value === 'selection') {
        newTypeResultFields = {
          reference_range: '',
          reference_range_2: '',
          reference_range_unit: '',
          possible_values: initialTypeResult.possible_values,
        }
      } else if (typeResultFields.type_of_input === 'selection') {
        newTypeResultFields = {
          possible_values: [],
          reference_range: '',
        }
      }
    }
    if (
      prop === 'possible_values' &&
      !value?.includes(typeResultFields.reference_range)
    ) {
      newTypeResultFields = {
        reference_range: '',
      }
    }
    setTypeResultFields({
      ...typeResultFields,
      ...newTypeResultFields,
      [prop]: value,
    })
  }

  const handleBlur = prop => event => {
    const { target } = event

    const value = target?.value.trim()

    setValidFields({
      ...validFields,
      [prop]: {
        error: false,
        message: '',
      },
    })

    if (!value.length) {
      setValidFields({
        ...validFields,
        [prop]: {
          error: true,
          message: ERROR_MESSAGES[prop],
        },
      })
    } else {
      setTypeResultFields({
        ...typeResultFields,
        [prop]: value,
      })
    }
  }

  const handleBlurChip = prop => {
    setValidFields({
      ...validFields,
      [prop]: {
        error: false,
        message: '',
      },
    })

    if (!typeResultFields[prop]?.length) {
      setValidFields({
        ...validFields,
        [prop]: {
          error: true,
          message: ERROR_MESSAGES[prop],
        },
      })
    }
  }

  const disabledButton = () =>
    Object.keys(INPUTS_TYPE).includes(typeResultFields.type_of_input) &&
    typeResultFields.testId &&
    typeResultFields.name &&
    ((typeResultFields.type_of_input !== 'selection' &&
      typeResultFields.reference_range) ||
      (typeResultFields.type_of_input === 'selection' &&
        typeResultFields.possible_values?.length)) &&
    !isLoading

  const handleCloseModal = () => {
    onClose()
    setTypeResultFields(emptyTypeResult)
    setValidFields(defaultError)
  }

  const handleClickButton = async () => {
    const typeResulToSend = {
      ...typeResultFields,
      possible_values: JSON.stringify(typeResultFields.possible_values),
    }
    if (await onSubmit(typeResulToSend)) {
      setTypeResultFields(emptyTypeResult)
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <DialogTitle sx={{ textAlign: 'center' }} variant="h6" component="h2">
        {typeResult.id ? 'Editar Tipo de Resultado' : 'Crear Tipo de Resultado'}{' '}
        para "{test.name}"
      </DialogTitle>
      <DialogContent>
        <Grid sx={styles.grid} container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              sx={styles.textField}
              size={sizeInput}
              error={validFields.name.error}
              fullWidth
              helperText={validFields.name.message}
              required
              label="Nombre"
              placeholder="Ingrese el nombre"
              value={typeResultFields.name}
              onChange={e => handleFieldChange(e.target.value, 'name')}
              onBlur={handleBlur('name')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <FormControl sx={styles.textField} fullWidth size={sizeInput}>
              <InputLabel>Tipo de entrada</InputLabel>
              <Select
                label="Tipo de entrada"
                value={typeResultFields.type_of_input}
                onChange={e =>
                  handleFieldChange(e.target.value, 'type_of_input')
                }
              >
                {Object.keys(INPUTS_TYPE).map(option => (
                  <MenuItem key={option} value={option}>
                    {INPUTS_TYPE[option].label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid sx={styles.grid} container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              sx={styles.textField}
              size={sizeInput}
              error={validFields.method.error}
              fullWidth
              helperText={validFields.method.message}
              label="Método"
              placeholder="Ingrese método"
              value={typeResultFields.method}
              onChange={e => handleFieldChange(e.target.value, 'method')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              sx={styles.textField}
              size={sizeInput}
              error={validFields.custom_reference_range.error}
              fullWidth
              multiline
              maxRows={3}
              helperText={validFields.custom_reference_range.message}
              label="Rango de referencia personalizado"
              placeholder="Ingrese rango de referencia personalizado"
              value={typeResultFields.custom_reference_range}
              onChange={e =>
                handleFieldChange(e.target.value, 'custom_reference_range')
              }
            />
          </Grid>
        </Grid>
        {typeResultFields['type_of_input'] === 'selection' ? (
          <>
            <Grid sx={styles.grid} container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  options={[]}
                  defaultValue={
                    initialTypeResult.possible_values
                      ? initialTypeResult.possible_values
                      : []
                  }
                  freeSolo
                  onChange={(e, value) => {
                    handleFieldChange(value, 'possible_values')
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      return (
                        <Chip
                          key={index}
                          label={option}
                          {...getTagProps({ index })}
                          color={
                            option === typeResultFields.reference_range
                              ? 'success'
                              : 'default'
                          }
                          clickable
                          onClick={value =>
                            handleFieldChange(
                              value.target.outerText,
                              'reference_range'
                            )
                          }
                        />
                      )
                    })
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Posibles opciones de selección"
                      placeholder="Ingrese las opciones de selección"
                      sx={styles.textField}
                      size={sizeInput}
                      error={validFields.possible_values.error}
                      fullWidth
                      helperText={validFields.possible_values.message}
                      required
                      onBlur={() => handleBlurChip('possible_values')}
                    />
                  )}
                />
                <FormHelperText>
                  * Para agregar una opción, escriba la opción y presione enter
                  <br />* Para seleccionar la opción de referencia, presione
                  sobre la opción
                </FormHelperText>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid sx={styles.grid} container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  sx={styles.textField}
                  size={sizeInput}
                  error={validFields.reference_range.error}
                  fullWidth
                  helperText={validFields.reference_range.message}
                  required
                  label="Rango de referencia inicial"
                  placeholder="Ingrese el rango de referencia inicial"
                  value={typeResultFields.reference_range}
                  onChange={e =>
                    handleFieldChange(e.target.value, 'reference_range')
                  }
                  onBlur={handleBlur('reference_range')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  sx={styles.textField}
                  size={sizeInput}
                  error={validFields.reference_range_2.error}
                  fullWidth
                  helperText={validFields.reference_range_2.message}
                  label="Rango de referencia final"
                  placeholder="Ingrese el rango de referencia final"
                  value={typeResultFields.reference_range_2}
                  onChange={e =>
                    handleFieldChange(e.target.value, 'reference_range_2')
                  }
                />
              </Grid>
            </Grid>

            <Grid sx={styles.grid} container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  sx={styles.textField}
                  size={sizeInput}
                  error={validFields.reference_range_unit.error}
                  fullWidth
                  helperText={validFields.reference_range_unit.message}
                  label="Unidad de rango de referencia"
                  placeholder="Ingrese unidad de rango de referencia"
                  value={typeResultFields.reference_range_unit}
                  onChange={e =>
                    handleFieldChange(e.target.value, 'reference_range_unit')
                  }
                />
              </Grid>
            </Grid>
          </>
        )}
      </DialogContent>
      <DialogActions sx={styles.buttonGroup}>
        <Button
          sx={styles.button}
          variant="outlined"
          color="primary"
          onClick={handleCloseModal}
        >
          Cancelar
        </Button>
        <Button
          sx={styles.button}
          variant="contained"
          color="primary"
          disabled={!disabledButton()}
          onClick={handleClickButton}
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ModalTypeResult.propTypes = {
  open: PropTypes.bool.isRequired,
  typeResult: PropTypes.instanceOf(Object),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

ModalTypeResult.defaultProps = {
  typeResult: {},
}

export default ModalTypeResult
