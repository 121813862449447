import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  openSnackBar: false,
  snackBarMessage: '',
  snackBarSeverity: 'error',
  snackBarDuration: 3000,
}

const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    fetchingLoading: (state, action) => {
      const { payload: isLoading } = action
      state.isLoading = isLoading
    },
    fetchingSnackbarShow: (state, action) => {
      const { payload: snackbar } = action
      state.snackBarMessage = snackbar.message
      state.snackBarSeverity = snackbar.severity
      state.snackBarDuration = snackbar.duration
      state.openSnackBar = true
    },
    fetchingSnackbarHide: state => {
      state.openSnackBar = initialState.openSnackBar
      state.snackBarMessage = initialState.snackBarMessage
      state.snackBarSeverity = initialState.snackBarSeverity
      state.snackBarDuration = initialState.snackBarDuration
    },
  },
})

export const { fetchingLoading, fetchingSnackbarShow, fetchingSnackbarHide } =
  pageSlice.actions

export default pageSlice.reducer
