import {
  fetchingUser,
  fetchingUserSuccess,
  fetchingUserFailure,
} from '../slices/userReducer'

import { client } from '../utils/client'
import { decodeToken } from '../utils/auth'

import { LOGIN_PATH, USER_PATH } from '../constants/endpointsConstants'

export const login = credentials => {
  return async dispatch => {
    try {
      dispatch(fetchingUser())

      const {
        data: { token },
      } = await client.post(LOGIN_PATH, {
        userName: credentials.user,
        password: credentials.password,
      })

      window.localStorage.setItem('token', token)

      const { id } = decodeToken(token)

      const {
        data: { data: user },
      } = await client.get(`${USER_PATH}/${id}`)

      dispatch(fetchingUserSuccess(user))
    } catch (error) {
      dispatch(fetchingUserFailure(error?.response?.data?.message))
    }
  }
}

export const validateToken = () => {
  return async dispatch => {
    const token = localStorage.getItem('token')
    if (token) {
      try {
        dispatch(fetchingUser())

        const { id } = decodeToken(token)
        const {
          data: { data: user },
        } = await client.get(`${USER_PATH}/${id}`)

        dispatch(fetchingUserSuccess(user))
      } catch (error) {
        dispatch(fetchingUserFailure(error?.response?.data?.message))
      }
    } else {
      dispatch(fetchingUserFailure(''))
    }
  }
}
