import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
  Button,
} from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import PropTypes from 'prop-types'
import ModalTest from '../../ModalTest/ModalTest'
import ScrollToHereOnMount from '../../../common/ScrollToHereOnMount/ScrollToHereOnMount'
import { setLoading, showSnackbar } from '../../../actions/pageActions'
import { addTestToAreas } from '../../../actions/rootActions'

import { client } from '../../../utils/client'
import { ADMIN } from '../../../constants/rolesConstants'
import { TESTS_PATH } from '../../../constants/endpointsConstants'

const Step1 = ({ tests, onChange }) => {
  const areas = useSelector(state => state.rootReducer.areas)
  const [testsChecked, setTestsChecked] = useState(
    tests.reduce((obj, item) => Object.assign(obj, { [item.id]: item }), {})
  )
  const [openNewTest, setOpenNewTest] = useState(false)
  const loginUserRole = useSelector(state => state.userReducer.user.role)
  const isAdmin = loginUserRole.name === ADMIN
  const dispatch = useDispatch()

  const handleToggle = value => () => {
    const newTestsChecked = { ...testsChecked }
    if (!testsChecked[value.id]) {
      newTestsChecked[value.id] = {
        id: value.id,
        name: value.name,
        cost: Number(value.suggestedCost),
        comment: '',
      }
    } else {
      delete newTestsChecked[value.id]
    }
    setTestsChecked(newTestsChecked)
    onChange(newTestsChecked)
  }

  const handleFieldChange = (prop, id) => event => {
    const {
      target: { value },
    } = event

    const newTestsChecked = { ...testsChecked }
    if (prop === 'cost') {
      if (value === '') {
        newTestsChecked[id][prop] = ''
      } else if (Number(value) >= 0) {
        newTestsChecked[id][prop] = Number(value)
      }
    } else {
      newTestsChecked[id][prop] = value
    }
    setTestsChecked(newTestsChecked)
    onChange(newTestsChecked)
  }

  const handleCreateTest = async newTest => {
    dispatch(setLoading(true))
    let success = false
    try {
      const {
        data: { message, data: test },
      } = await client.post(`${TESTS_PATH}`, newTest)

      dispatch(addTestToAreas(test))
      dispatch(showSnackbar(message, 'success'))
      const testChecked = {
        id: test.id,
        name: test.name,
        cost: Number(test.suggestedCost),
        comment: '',
      }
      onChange({ [test.id]: testChecked, ...testsChecked })
      setTestsChecked({ [test.id]: testChecked, ...testsChecked })
      success = true
    } catch (error) {
      dispatch(showSnackbar(error?.response?.data?.message))
    }

    dispatch(setLoading(false))
    return success
  }

  const handleOpenModalTest = () => {
    setOpenNewTest(true)
  }

  const handleCloseModalTest = () => {
    setOpenNewTest(false)
  }

  const handleBlur = (prop, id) => event => {
    const { target } = event
    const value = target?.value.trim()
    const newTestsChecked = { ...testsChecked }

    if (Number(value) === 0) {
      newTestsChecked[id][prop] = Number(value)
    }
    setTestsChecked(newTestsChecked)
    onChange(newTestsChecked)
  }

  return (
    <>
      <ScrollToHereOnMount />
      {isAdmin && (
        <Grid container justifyContent="flex-end">
          <Button
            sx={{ marginBlock: '10px' }}
            variant="contained"
            startIcon={<AddCircleIcon />}
            onClick={handleOpenModalTest}
          >
            Nuevo Análisis
          </Button>
        </Grid>
      )}
      {areas.map(area => (
        <Accordion key={area.id} TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{area.name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List sx={{ width: '100%' }}>
              {area.Tests.map(test => {
                return (
                  <ListItem
                    key={test.id}
                    alignItems="flex-start"
                    disablePadding
                  >
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xl={7} md={6} xs={12}>
                        <ListItemButton
                          role={undefined}
                          onClick={handleToggle(test)}
                          dense
                        >
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={!!testsChecked[test.id]}
                              tabIndex={-1}
                              disableRipple
                            />
                          </ListItemIcon>
                          <ListItemText primary={test.name} />
                        </ListItemButton>
                      </Grid>

                      <Grid item xl={2} md={2} xs={12}>
                        <TextField
                          label="Costo"
                          variant="standard"
                          sx={{ m: 1 }}
                          fullWidth
                          size="small"
                          disabled={!testsChecked[test.id]}
                          onChange={handleFieldChange('cost', test.id)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                Bs.
                              </InputAdornment>
                            ),
                          }}
                          value={
                            testsChecked[test.id]?.cost === undefined
                              ? test?.suggestedCost
                              : testsChecked[test.id]?.cost
                          }
                          onBlur={handleBlur('cost', test.id)}
                          type="number"
                        />
                      </Grid>

                      <Grid item xl={3} md={4} xs={12}>
                        <TextField
                          label="Comentario"
                          variant="standard"
                          sx={{ m: 1 }}
                          fullWidth
                          multiline
                          disabled={!testsChecked[test.id]}
                          onChange={handleFieldChange('comment', test.id)}
                          size="small"
                          maxRows={3}
                          value={testsChecked[test.id]?.comment}
                        />
                      </Grid>
                    </Grid>
                  </ListItem>
                )
              })}
            </List>
          </AccordionDetails>
        </Accordion>
      ))}
      {openNewTest && (
        <ModalTest
          open={openNewTest}
          onSubmit={handleCreateTest}
          onClose={handleCloseModalTest}
        />
      )}
    </>
  )
}

Step1.propTypes = {
  patient: PropTypes.instanceOf(Array),
  onChange: PropTypes.func.isRequired,
}

Step1.defaultProps = {
  tests: [],
}

export default Step1
