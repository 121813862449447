import {
  fetchingLoading,
  fetchingSnackbarShow,
  fetchingSnackbarHide,
} from '../slices/pageReducer'

export const setLoading = value => {
  return async dispatch => {
    dispatch(fetchingLoading(value))
  }
}

export const showSnackbar = (
  message = 'Algo no salio bien',
  severity = 'error',
  duration = 3000
) => {
  return async dispatch => {
    dispatch(fetchingSnackbarShow({ message, severity, duration }))
  }
}

export const hideSnackbar = () => {
  return async dispatch => {
    dispatch(fetchingSnackbarHide())
  }
}
