import { client } from '../utils/client'

import {
  fetchingRolesSuccess,
  fetchingSubsidiariesSuccess,
  fetchingAreasSuccess,
  fetchingAddTestToArea,
} from '../slices/rootReducer'

import { setLoading, showSnackbar } from './pageActions'

import {
  ROLES_PATH,
  SUBSIDIARIES_PATH,
  AREAS_PATH,
} from '../constants/endpointsConstants'

export const fetchRoles = () => {
  return async dispatch => {
    try {
      dispatch(setLoading(true))
      const { data: roles } = await client.get(ROLES_PATH)
      dispatch(fetchingRolesSuccess(roles))
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setLoading(false))
      dispatch(showSnackbar())
    }
  }
}

export const fetchSubsidiaries = () => {
  return async dispatch => {
    try {
      dispatch(setLoading(true))
      const { data: subsidiaries } = await client.get(SUBSIDIARIES_PATH)
      dispatch(fetchingSubsidiariesSuccess(subsidiaries?.data))
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setLoading(false))
      dispatch(showSnackbar())
    }
  }
}

export const fetchAreas = () => {
  return async dispatch => {
    try {
      dispatch(setLoading(true))
      const { data: areas } = await client.get(AREAS_PATH)
      dispatch(fetchingAreasSuccess(areas?.data))
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setLoading(false))
      dispatch(showSnackbar())
    }
  }
}

export const addTestToAreas = newTest => {
  return async dispatch => {
    dispatch(fetchingAddTestToArea(newTest))
  }
}
