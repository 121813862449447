import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { client } from '../../utils/client'
import { isEmpty } from 'lodash'

import PersonAddIcon from '@mui/icons-material/PersonAdd'
import EditButton from '@mui/icons-material/Edit'

import {
  Button,
  Container,
  Grid,
  IconButton,
  Switch,
  Typography,
} from '@mui/material'
import DataGrid from '../../components/DataGrid/DataGrid'
import ModalUser from '../../components/ModalUser/ModalUser'
import DeleteUser from '../../components/User/DeleteUser'
import DisplayCredentials from '../../components/DisplayCredentials/DisplayCredentials'
import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog'

import { setLoading, showSnackbar } from '../../actions/pageActions'

import {
  USER_PATH,
  CHANGE_STATUS_PATH,
} from '../../constants/endpointsConstants'
import { ADMIN, MANAGER, WORKER } from '../../constants/rolesConstants'

const UsersList = () => {
  const dispatch = useDispatch()

  const roles = useSelector(state => state.rootReducer.roles)
  const subsidiaries = useSelector(state => state.rootReducer.subsidiaries)
  const loginUser = useSelector(state => state.userReducer.user)

  const [users, setUsers] = useState([])
  const [userSelected, setUserSelected] = useState({})
  const [loaderTable, setLoaderTable] = useState(true)
  const [open, setOpen] = useState(false)
  const [openCredentials, setOpenCredentials] = useState({
    open: false,
    password: '',
    userName: '',
  })

  const removeUser = userId => {
    const newList = users.filter(user => user.id !== userId)
    setUsers(newList)
  }

  const columns = [
    { field: 'name', headerName: 'Nombre', flex: 0.9, minWidth: 150 },
    {
      field: 'lastName',
      headerName: 'Apellido(s)',
      flex: 0.9,
      minWidth: 150,
      valueGetter: params =>
        `${params.row.lastNameFather} ${params.row.lastNameMother}`,
    },
    {
      field: 'subsidiary',
      headerName: 'Sucursal',
      flex: 1,
      minWidth: 190,
      valueGetter: params =>
        `${params.row.subsidiary ? params.row.subsidiary.name : '-'}`,
    },
    {
      field: 'role',
      headerName: 'Rol',
      flex: 1,
      minWidth: 100,
      valueGetter: params => `${params.row.role.label}`,
    },
    {
      field: 'phone',
      headerName: 'Teléfono',
      flex: 0.6,
      minWidth: 100,
    },
    {
      field: 'identityCard',
      headerName: 'CI',
      flex: 0.6,
      minWidth: 100,
    },
    {
      field: 'userName',
      headerName: 'Cuenta',
      flex: 0.6,
      minWidth: 100,
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      flex: 0.6,
      minWidth: 150,
      sortable: false,
      renderCell: params => (
        <>
          <IconButton
            color="primary"
            onClick={() => handleSelectUser(params.row)}
          >
            <EditButton />
          </IconButton>
          {loginUser?.role?.name === ADMIN && (
            <DeleteUser user={params.row} onDelete={removeUser} />
          )}
          {(loginUser?.role?.name === ADMIN ||
            loginUser?.role?.name === MANAGER) && (
            <ConfirmDialog
              buttonOpen={<Switch checked={params.row.status === 1} />}
              body={`¿Deseas ${
                params.row.status === 1 ? 'deshabilitar' : 'habilitar'
              } a ${params.row.name} ${params.row.lastNameFather} ${
                params.row.lastNameMother
              }?`}
              onConfirm={() => handleChangeStatusUser(params.row.id)}
            />
          )}
        </>
      ),
    },
  ]

  const handleLoading = value => {
    setLoaderTable(value)
    dispatch(setLoading(value))
  }

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    setUserSelected({})
  }

  const handleChangeStatusUser = async id => {
    dispatch(setLoading(true))
    try {
      const {
        data: { userStatus },
      } = await client.post(`${USER_PATH}/${CHANGE_STATUS_PATH}/${id}`)

      const userIndex = users.findIndex(user => user.id === id)
      let userList = [...users]

      userList[userIndex].status = userStatus
      setUsers(userList)
      dispatch(
        showSnackbar(
          `Usuario ${
            userStatus === 1 ? 'habilitado' : 'deshabilitado'
          } con éxito`,
          'success'
        )
      )
    } catch (error) {
      dispatch(showSnackbar())
    }
    dispatch(setLoading(false))
  }

  useEffect(() => {
    handleLoading(true)
    client
      .get(USER_PATH)
      .then(response => {
        setUsers(response.data.data)
        handleLoading(false)
      })
      .catch(error => {
        handleLoading(false)
        dispatch(showSnackbar())
      })
    // eslint-disable-next-line
  }, [])

  const handleCreateUser = async newUser => {
    let success = false
    if (loginUser?.role?.name === ADMIN) {
      if (!newUser.roleId) {
        newUser.roleId = roles[0]?.id
      }
      if (newUser.roleId === 1) {
        newUser.subsidiaryId = ''
      } else if (!newUser.subsidiaryId) {
        newUser.subsidiaryId = subsidiaries[0]?.id
      }
    } else {
      newUser.roleId = roles.find(role => role.name === WORKER)?.id
      newUser.subsidiaryId = loginUser.subsidiary.id
    }

    try {
      dispatch(setLoading(true))

      const {
        data: { message, data: user },
      } = await client.post(USER_PATH, newUser)

      dispatch(showSnackbar(message, 'success'))
      dispatch(setLoading(false))
      handleClose()

      setOpenCredentials({
        open: true,
        password: user?.password,
        userName: user?.userName,
      })
      setUsers([...users, user])
      success = true
    } catch (error) {
      dispatch(setLoading(false))
      dispatch(showSnackbar(error?.response?.data?.message))
    }
    return success
  }

  const handleSelectUser = user => {
    setUserSelected(user)
    setOpen(true)
  }

  const handleEditUser = async updateUser => {
    let success = false
    let userName = ''
    let newPassword = ''

    if (loginUser?.role?.name === ADMIN) {
      if (updateUser.roleId === 1) {
        updateUser.subsidiaryId = null
      } else if (!updateUser.subsidiaryId) {
        updateUser.subsidiaryId = subsidiaries[0]?.id
      }
    } else {
      updateUser.roleId = userSelected?.subsidiary?.id
      updateUser.subsidiaryId = userSelected?.role?.id
    }
    try {
      dispatch(setLoading(true))

      const {
        data: { message, data: user },
      } = await client.put(`${USER_PATH}/${userSelected?.id}`, updateUser)

      if (updateUser.resetPassword) {
        const {
          data: { data },
        } = await client.post(`${USER_PATH}/${userSelected?.id}/newPassword`)

        userName = data?.userName
        newPassword = data?.password
      }

      dispatch(setLoading(false))
      dispatch(showSnackbar(message, 'success'))
      handleClose()

      if (userName && newPassword) {
        setOpenCredentials({
          open: true,
          password: newPassword,
          userName,
        })
      }

      const listUpdate = users.map(item => (item.id === user.id ? user : item))
      setUsers(listUpdate)
      success = true
    } catch (error) {
      dispatch(setLoading(false))
      dispatch(showSnackbar(error?.response?.data?.message))
    }
    return success
  }

  return (
    <Container maxWidth="xl">
      <Typography variant="h3" sx={{ marginBlock: '25px' }}>
        Lista de Usuarios
      </Typography>
      <Grid container justifyContent="flex-end">
        <Button
          sx={{ marginBlock: '10px' }}
          variant="contained"
          startIcon={<PersonAddIcon />}
          onClick={handleOpen}
        >
          Agregar
        </Button>
        {open && (
          <ModalUser
            open={open}
            user={userSelected}
            onClose={handleClose}
            onSubmit={isEmpty(userSelected) ? handleCreateUser : handleEditUser}
          />
        )}
        <DisplayCredentials
          open={openCredentials.open}
          password={openCredentials.password}
          userName={openCredentials.userName}
          onClose={() =>
            setOpenCredentials({ password: '', userName: '', open: false })
          }
        />
      </Grid>
      <DataGrid rows={users} columns={columns} loading={loaderTable} />
    </Container>
  )
}

export default UsersList
